import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Chip,
  ClickAwayListener,
  Fade,
  Grid,
  Paper,
  Popper,
  Avatar,
  List,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  Typography,
  ListItemButton,
  ButtonBase,
  Box,
  alpha
} from '@mui/material';

// third party
import PerfectScrollbar from 'react-perfect-scrollbar';

// assets
import QueryBuilderTwoToneIcon from '@mui/icons-material/QueryBuilderTwoTone';
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';

import User1 from 'assets/images/users/avatar-1.jpg';
import { IconBell } from '@tabler/icons-react';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          minWidth: { sm: 50, xs: 35 },
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: alpha(theme.palette.common.white, 0.15),
              color: theme.palette.background.paper,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: alpha(theme.palette.common.white, 0.25),
                color: theme.palette.background.paper
              }
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
          >
            <IconBell stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10]
            }
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true // false by default
            }
          }
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 350,
                    minWidth: 250,
                    maxHeight: 300,
                    overflowY: 'auto',
                    backgroundColor: theme.palette.primary.primary,
                    pb: 0,
                    borderRadius: '10px',
                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
                  }}
                >
                  <ListItemButton alignItemsFlexStart>
                    <ListItemAvatar>
                      <Avatar alt="Joseph William" src={User1} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<Typography variant="subtitle1">BLive</Typography>}
                      secondary={<Typography variant="subtitle2">Welcome aboard</Typography>}
                    />
                    <ListItemSecondaryAction sx={{ top: 22 }}>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <QueryBuilderTwoToneIcon
                            sx={{
                              fontSize: '0.75rem',
                              mr: 0.5,
                              color: theme.palette.grey[400]
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" display="block" gutterBottom sx={{ color: theme.palette.grey[400] }}>
                            now
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </List>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
