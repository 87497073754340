import { jwtDecode } from 'jwt-decode';
import { createContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { clearUserAndToken, setToken, useLazyGetUserByIdQuery } from 'store/features/slices/userSlice';
import styled from 'styled-components';
import loadingGif from 'assets/images/liveb.gif';
import { setMyCompany } from 'store/features/slices/companySlice';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [auth, setAuth] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  const [user, setUser] = useState({ type: null, role: null });
  const [fetchUser, { data, isFetching }] = useLazyGetUserByIdQuery();
  const [isLoading, setIsLoading] = useState(true);

  const handleLogin = async () => {
    const isLogedIn = await localStorage.getItem('auth-token');
    if (!['/login', '/register', '/forgot'].includes(pathname)) handleToken(isLogedIn);
    else
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
  };

  const handleToken = (data) => {
    if (data !== null && data !== 'null' && data !== undefined && data !== 'undefined') {
      const decodedToken = jwtDecode(data);
      if (decodedToken.exp) {
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp < currentTime || decodedToken.exp - currentTime > 24 * 60 * 60) {
          handleLogout();
          return;
        }
      }

      setAuth(data);
      setUser({
        type: decodedToken._type || null,
        role: decodedToken._role || null,
        id: decodedToken._id || null
      });
      dispatch(setToken(data));
      fetchUser(decodedToken?._id);
    } else {
      navigate('/login');
    }

    // setIsLoading(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  };

  const handleLogout = () => {
    dispatch(clearUserAndToken());
    dispatch(setMyCompany({}));
    setAuth(null);
    window.location.assign('http://blive.rw');
  };

  useEffect(() => {
    handleLogin();
  }, []);

  if (isLoading) {
    return (
      <LoadingContainer>
        {/* <LoadingMessage>App Loading...</LoadingMessage> */}
        <LoadingImage src={loadingGif} alt="Loading..." />
      </LoadingContainer>
    );
  }

  return <AuthContext.Provider value={{ auth, setAuth, activeTab, setActiveTab, user, setUser }}>{children}</AuthContext.Provider>;
};

export default AuthContext;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgba(18, 9, 88, 1);
`;

const LoadingMessage = styled.p`
  font-size: 20px;
  color: white;
`;

const LoadingImage = styled.img`
  max-width: 100%;
  width: 200px;
  height: auto;
`;
