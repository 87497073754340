import React, { useEffect } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import { NumberInput } from '@mantine/core';

const InputContainer = styled.div`
  margin-bottom: 10px;
`;

const ErrorContainer = styled.div`
  margin-top: 5px;
`;

const ErrorText = styled.div`
  color: #ff0000;
  font-size: 14px;
  background-color: #ffe8e8;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ff0000;
`;

const CustomNumberInput = ({
  name,
  placeholder,
  label,
  hideControls = true,
  withAsterisk = false,
  max = false,
  maxvalue,
  prefix,
  findTotalFromQuantity = false,
  findTotalFromUnitPrice = false,
  disabled = false,
  readOnly = false
}) => {
  const { setFieldValue, values } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (value) => {
    setFieldValue(name, value);
    if (findTotalFromQuantity) {
      const itemIndex = name.split('.')[1];
      const suprice = values.sales[itemIndex].suprice;
      setFieldValue(`sales.${itemIndex}.stotal`, value * suprice || undefined);
    }

    if (findTotalFromUnitPrice) {
      const itemIndex = name.split('.')[1];
      const quantity = values.sales[itemIndex].quantity;
      setFieldValue(`sales.${itemIndex}.stotal`, value * quantity || undefined);
    }
  };

  return (
    <InputContainer>
      <NumberInput
        value={field.value}
        onChange={handleChange}
        placeholder={placeholder}
        clampBehavior="strict"
        min={1}
        allowNegative={false}
        hideControls={hideControls}
        label={label}
        readOnly={readOnly}
        thousandSeparator=","
        withAsterisk={withAsterisk}
        max={max ? maxvalue : undefined}
        suffix={prefix ? prefix : undefined}
        error={meta.touched && meta.error ? meta.error : false}
        disabled={disabled}
      />
    </InputContainer>
  );
};

export default CustomNumberInput;
