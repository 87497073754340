import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Typography, Button } from '@mui/material';

const ForbiddenContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const ForbiddenBox = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const ForbiddenPage = () => {
  return (
    <ForbiddenContainer>
      <ForbiddenBox>
        <Typography variant="h3" gutterBottom>
          Access Forbidden
        </Typography>
        <Typography variant="body1" paragraph>
          You are not allowed to access this page.
        </Typography>
      </ForbiddenBox>

      <Button component={Link} to="/" variant="contained" color="primary">
        Go Home
      </Button>
    </ForbiddenContainer>
  );
};

export default ForbiddenPage;
