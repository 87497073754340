import React, { useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { transformPurchaseData, transformSalesData } from './Assets/FakeData';
import PrintIcon from '@mui/icons-material/Print';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import SellingBill from 'views/Bills/SellingBill';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import Iconify from './iconify/iconify';
import { useGetAllCompanySupplierQuery } from 'store/features/slices/companySlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useGetAllProductsQuery } from 'store/features/slices/productSlice';
import { Restore, ContentCopy } from '@mui/icons-material';
import { CircularProgress, Button, Box, FormControl, Grid, IconButton, MenuItem, Select, Tooltip, InputLabel } from '@mui/material';
import { useMakeRefundMutation } from 'store/features/slices/salesSlicee';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';

const MaterialReactTableFiltered = ({
  dataList,
  type,
  filterDates,
  setFilterDates,
  handleFilterByDates,
  isFetching = false,
  title = ''
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [billInfo, setBillInfo] = React.useState(null);
  const [isSure, setIsSure] = React.useState(false);
  const theme = useTheme();
  const { data: companyUserSupplier = [], isLoading: expenseLoading } = useGetAllCompanySupplierQuery();
  const { data: productList, isLoading: productLoading } = useGetAllProductsQuery();
  const [makeRefund, refundResponse] = useMakeRefundMutation();

  const columns = useMemo(() => {
    const commonColumns = [
      {
        header: 'Ref Code',
        accessorKey: 'refCode',
        enableGrouping: false,
        size: 240
      }
    ];

    const typeSpecificColumns =
      type === 'sale'
        ? [
            {
              header: 'Date',
              accessorKey: 'saleDate',
              enableGrouping: false
            },
            {
              header: 'Name',
              accessorKey: 'name',
              enableGrouping: false
            },
            {
              header: 'Quantity',
              accessorKey: 'qty',
              enableGrouping: false
            },
            {
              header: 'Unit Price',
              accessorKey: 'unitPrice',
              enableGrouping: false
            },

            {
              header: 'Total  Amount',
              accessorKey: 'stotal',
              enableGrouping: false,
              aggregationFn: 'sum',
              AggregatedCell: ({ cell, table }) => {
                return (
                  <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
                    {cell.getValue()?.toLocaleString?.('en-US', {
                      style: 'currency',
                      currency: 'Rwf',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </Box>
                );
              }
            },
            {
              header: 'Print',
              accessorKey: 'action',
              enableGrouping: false,
              AggregatedCell: ({ cell, table, row }) => {
                return (
                  <>
                    {row?.original?.billInfo?.saleCopyNum < 2 && (
                      <Tooltip title="print">
                        <IconButton
                          onClick={() => {
                            setBillInfo({ title, ...row?.original?.billInfo });
                            if (row?.original?.billInfo) {
                              open();
                            }
                          }}
                        >
                          <PrintIcon style={{ color: '#5ba65a' }} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {title != 'PROFORMA' && row?.original?.billInfo?.isRefunded == '0' && (
                      <Tooltip title="refund">
                        <IconButton
                          onClick={() => {
                            setIsSure(true);
                            setBillInfo(row?.original?.billInfo);
                          }}
                        >
                          <Restore style={{ color: '#5ba65a' }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                );
              }
            }
          ]
        : [
            {
              header: 'Date',
              accessorKey: 'purchaseDate',
              enableGrouping: false
            },
            {
              header: 'Name',
              accessorKey: 'name',
              enableGrouping: false
            },
            {
              header: 'Batch No',
              accessorKey: 'batchNo',
              enableGrouping: false
            },
            {
              header: 'Quantity',
              accessorKey: 'qty',
              enableGrouping: false
            },
            {
              header: 'Unit Price',
              accessorKey: 'purchaseUnitPrice',
              enableGrouping: false
            },
            {
              header: 'Supplier',
              accessorKey: 'supplier',
              enableGrouping: false
            },
            {
              header: 'Comment',
              accessorKey: 'comment',
              enableGrouping: false
            },
            {
              header: 'Total Price',
              accessorKey: 'ptotal',
              enableGrouping: false,
              aggregationFn: 'sum',
              size: 200,
              AggregatedCell: ({ cell, table }) => {
                return (
                  <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
                    {cell.getValue()?.toLocaleString?.('en-US', {
                      style: 'currency',
                      currency: 'Rwf',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </Box>
                );
              }
            }
          ];

    return [...commonColumns, ...typeSpecificColumns];
  }, [type]);

  const columnss = useMemo(
    () => [
      {
        header: 'Ref Code',
        accessorKey: 'refCode',
        enableGrouping: false,
        size: 240
      },
      {
        header: 'Date',
        accessorKey: 'saleDate',
        enableGrouping: false
      },
      {
        header: 'Name',
        accessorKey: 'productName',
        enableGrouping: false
      },
      {
        header: 'Quantity',
        accessorKey: 'qty',
        enableGrouping: false
      },
      {
        header: 'Unit Price',
        accessorKey: 'unitPrice',
        enableGrouping: false
      },
      {
        header: 'Total Price',
        accessorKey: 'totalPrice',
        enableGrouping: false
      }
    ],
    []
  );

  const handleChange = (event) => {
    setFilterDates((prevFilterDates) => ({
      ...prevFilterDates,
      user: event.target.value
    }));
  };
  const handleProductChange = (event) => {
    setFilterDates((prevFilterDates) => ({
      ...prevFilterDates,
      productId: event.target.value
    }));
  };

  if (refundResponse.isSuccess) {
    notifications.show({
      id: 'refund',
      withCloseButton: true,
      autoClose: 3000,
      title: 'REFUND',
      message: `Refunded Successfully!`,
      icon: <IconX />,
      loading: false
    });
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          close();
          setBillInfo(null);
        }}
        size="xl"
        centered
      >
        {billInfo ? <SellingBill billInfo={billInfo} /> : null}
      </Modal>

      <Modal title="Refund" centered opened={isSure} onClose={() => setIsSure(false)}>
        <p style={{ textAlign: 'center' }}>Are you sure you want a refund?</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => setIsSure(false)}>Close</Button>
          <Button
            variant="contained"
            onClick={() => {
              makeRefund({ saleId: billInfo?.id });
              setIsSure(false);
            }}
            disabled={refundResponse?.isLoading}
          >
            Yes
          </Button>
        </div>
      </Modal>

      <div style={{ height: 'auto' }}>
        <MaterialReactTable
          columns={columnss}
          data={dataList}
          enableColumnResizing
          enableStickyHeader
          enableStickyFooter
          positionToolbarAlertBanner="none"
          renderTopToolbarCustomActions={() => {
            return (
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <DatePicker
                        label="From"
                        format="DD/MM/YYYY"
                        value={filterDates.from ? dayjs(filterDates.from) : null}
                        onChange={(date) => setFilterDates({ ...filterDates, from: date ? dayjs(date).format('YYYY-MM-DD') : null })}
                      />
                    </Grid>
                    <Grid item>
                      <DatePicker
                        label="To"
                        format="DD/MM/YYYY"
                        value={filterDates.to ? dayjs(filterDates.to) : null}
                        onChange={(date) => setFilterDates({ ...filterDates, to: date ? dayjs(date).format('YYYY-MM-DD') : null })}
                      />
                    </Grid>
                    {type === 'purchase' && (
                      <Grid item>
                        <FormControl sx={{ m: 1, minWidth: 100 }}>
                          <InputLabel id="demo-simple-select-autowidth-label">Supplier</InputLabel>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={filterDates.user || ''}
                            onChange={handleChange}
                            autoWidth
                            label="Select User"
                          >
                            <MenuItem value={null}>
                              <em>None</em>
                            </MenuItem>
                            {companyUserSupplier?.map((supplier) => (
                              <MenuItem key={supplier.id} value={supplier.id}>
                                {supplier.names}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item>
                      <FormControl sx={{ m: 1, minWidth: 100 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Product</InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          value={filterDates.productId || ''}
                          onChange={handleProductChange}
                          autoWidth
                          label="Select Product"
                        >
                          <MenuItem value={null}>
                            <em>None</em>
                          </MenuItem>
                          {productList &&
                            productList.rows?.map((product) => (
                              <MenuItem key={product.id} value={product.id}>
                                {product.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item>
                      <Tooltip title="Filter List">
                        <IconButton
                          onClick={handleFilterByDates}
                          disabled={isFetching}
                          sx={{
                            background: 'white',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)'
                          }}
                        >
                          {isFetching ? (
                            <CircularProgress size={15} sx={{ color: theme.palette.primary.primary }} />
                          ) : (
                            <Iconify icon="eva:search-fill" sx={{ color: 'gray' }} />
                          )}
                          {/* <Iconify icon="eva:search-fill" sx={{ color: 'gray' }} /> */}
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </Box>
            );
          }}
          muiToolbarAlertBannerChipProps={{ color: 'success' }}
          muiTableContainerProps={{ sx: { maxHeight: 700 } }}
          muiTableHeadProps={{
            sx: {
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: 'rgba(18, 9, 88, 1)',
              color: 'white'
            }
          }}
        />
      </div>
    </>
  );
};

export default MaterialReactTableFiltered;
