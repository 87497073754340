import { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { Container } from '@mui/system';
import Divider from '@mui/material/Divider';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { Modal, PasswordInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import * as Yup from 'yup';
import { Field, Form, Formik, useFormikContext } from 'formik';

import Scrollbar from 'component/scrollbar/scrollbar';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';

import TableNoData from '../table-no-data';
import UserTableHead from '../user-table-head';
import TableEmptyRows from '../table-empty-rows';
import { emptyRows, applyFilter, getComparator } from '../utils';
import { useTheme } from '@mui/material/styles';
import LoadingButton from 'component/Loader/LoadingButton';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import ErrorMessageResponse from 'component/ErrorMessage/ErrorMessageResponse';
import { CustomInput } from 'component/inputs/TextInput';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { usePayLoanMutation } from 'store/features/slices/stockClosingSlice';
import CustomNumberInput from 'component/inputs/NumberInput';
import { CustomTextArea } from 'component/inputs/TextArea';
import { RenderSkeletons } from 'component/Assets/FakeData';
import NoDataFound from 'component/Assets/NoDataFound';
import UserTableToolbar from '../user-table-toolbar';
import { useLazyGetLoansQuery } from 'store/features/slices/expenseSlice';

import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';

import Iconify from 'component/iconify/iconify';
import Label from 'component/label/label';
import { formatNumberWithCommas } from 'component/Assets/FakeData';

function UserTableRow({ selected, loanDate, clientName, amount, status, saleId, handleClick, handlePay, handleViewMore }) {
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {loanDate}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>{clientName}</TableCell>
        <TableCell>{formatNumberWithCommas(amount)}</TableCell>
        <TableCell>{saleId}</TableCell>
        <TableCell>
          <Label color={status == 'UNPAID' ? 'warning' : 'success'}>{status}</Label>
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ sx: { width: 140 } }}
      >
        <MenuItem
          onClick={() => {
            handlePay();
            handleCloseMenu();
          }}
        >
          <Iconify icon="eva:credit-card-outline" sx={{ mr: 2 }} />
          Pay now
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleViewMore();
            handleCloseMenu();
          }}
        >
          <Iconify icon="eva:arrow-left-outline" sx={{ mr: 2 }} />
          View more
        </MenuItem>
      </Popover>
    </>
  );
}

export default function IncomeReportPage() {
  const theme = useTheme();
  const [activeRow, setActiveRow] = useState({});
  const [showError, setShowError] = useState(false);
  const toggleShowError = () => setShowError(!showError);
  const [opened, { open, close, toggle }] = useDisclosure(false);
  const [openDetails, { toggle: toggleDetails }] = useDisclosure(false);
  const [payLoan, { isLoading, isError, error, isSuccess }] = usePayLoanMutation();
  const [fetchData, { data: IncomeReportList = [], isLoading: IncomeReportLoading, isFetching }] = useLazyGetLoansQuery();

  const [filterDates, setFilterDates] = useState({ from: null, to: null });

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = IncomeReportList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: IncomeReportList,
    comparator: getComparator(order, orderBy),
    filterName
  });

  const notFound = !dataFiltered.length && !!filterName;

  const handleFilterByDates = () => {
    if (filterDates?.from !== null && filterDates?.to !== null) {
      fetchData(filterDates);
    } else {
      console.log("Please select both 'From' and 'To' dates before searching.");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container maxWidth="xl">
      <Modal opened={opened} onClose={() => close()} size="sm" centered closeOnClickOutside={false}>
        <Box px={4} pb={4}>
          <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
            Pay {activeRow?.amount}
          </Typography>
          <Formik
            initialValues={{ amount: activeRow?.amount, loanId: activeRow?.id }}
            validationSchema={Yup.object().shape({})}
            onSubmit={async (values, actions) => {
              try {
                const result = await payLoan(values).unwrap();
                actions.resetForm();
                actions.setSubmitting(false);
                close();
                notifications.show({
                  id: 'loan',
                  withCloseButton: true,
                  autoClose: 3000,
                  title: 'Loan',
                  message: `Loan Paid Successfully!`,
                  icon: <IconX />,
                  loading: false
                });
              } catch (err) {
                actions.setSubmitting(false);
                toggleShowError(true);
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, errors, values, setFieldValue }) => {
              return (
                <Form>
                  <CustomNumberInput name="amount" label="Amount" placeholder="Amount" readOnly={false} />
                  {showError && !isLoading && isError && !isSubmitting && (
                    <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                      <ErrorMessageResponse>{error?.data?.message || error.message || 'An error occurred'}</ErrorMessageResponse>
                    </Grid>
                  )}
                  <LoadingButton
                    sx={{ backgroundColor: theme.palette.primary.primary, color: 'white' }}
                    loading={isSubmitting}
                    fullWidth={true}
                    done={false}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </LoadingButton>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>

      <Modal opened={openDetails} onClose={toggleDetails} size="xl" centered closeOnClickOutside={false}>
        <Box px={4} pb={4}>
          <Typography variant="h3" color={theme.palette.primary.primary} gutterBottom>
            Sales Details <small style={{ float: 'right' }}>{activeRow?.sale?.refCode}</small>
          </Typography>

          <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
            First Payment
          </Typography>
          <Stack my={2} spacing={3} direction="row" useFlexGap flexWrap="wrap">
            <span>Loan: {activeRow?.sale?.loan}</span>
            <span>Mobile money: {activeRow?.sale?.mobileMoney}</span>
            <span>Cash: {activeRow?.sale?.cashInHand}</span>
            <span>Bank Card: {activeRow?.sale?.bankCard}</span>
            <span>Cheque: {activeRow?.sale?.bankCheque}</span>
          </Stack>
          <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
            Products List
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Product</TableCell>
                <TableCell align="right">Qty</TableCell>
                <TableCell align="right">Unit Price</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeRow?.sale?.products?.map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{row.productName}</TableCell>
                  <TableCell align="right">{row.qty}</TableCell>
                  <TableCell align="right">{row.unitPrice}</TableCell>
                  <TableCell align="right">{row.totalPrice}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Modal>

      <Typography variant="h4" style={{ fontWeight: 'bold' }} mb={2}>
        Loan Report
      </Typography>
      {IncomeReportLoading ? (
        RenderSkeletons()
      ) : (
        <Card>
          <UserTableToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            filterDates={filterDates}
            setFilterDates={setFilterDates}
            handleFilterByDates={handleFilterByDates}
            isFetching={isFetching}
          />

          {IncomeReportList && IncomeReportList?.length > 0 ? (
            <>
              <Scrollbar>
                <TableContainer sx={{ overflow: 'unset' }}>
                  <Table sx={{ minWidth: 800 }}>
                    <UserTableHead
                      order={order}
                      orderBy={orderBy}
                      rowCount={IncomeReportList.length}
                      numSelected={selected.length}
                      onRequestSort={handleSort}
                      onSelectAllClick={handleSelectAllClick}
                      headLabel={[
                        { id: 'loanDate', label: 'Date' },
                        { id: 'clientName', label: 'Client' },
                        { id: 'amount', label: 'Amount' },
                        { id: 'saleId', label: 'Sales Code' },
                        { id: 'status', label: 'Status' },
                        { id: '' }
                      ]}
                    />
                    <TableBody>
                      {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <UserTableRow
                          key={row.id}
                          handlePay={() => {
                            setActiveRow(row);
                            toggle();
                          }}
                          handleViewMore={() => {
                            setActiveRow(row);
                            toggleDetails();
                          }}
                          amount={row.amount}
                          saleId={row.saleId}
                          status={row.status}
                          loanDate={row.loanDate}
                          clientName={row.clientName}
                          selected={selected.indexOf(row.name) !== -1}
                          handleClick={(event) => handleClick(event, row.name)}
                        />
                      ))}

                      <TableEmptyRows height={77} emptyRows={emptyRows(page, rowsPerPage, IncomeReportList.length)} />

                      {notFound && <TableNoData query={filterName} />}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                page={page}
                component="div"
                count={IncomeReportList.length}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <NoDataFound message="No Closing Report Found!" />
          )}
        </Card>
      )}
    </Container>
  );
}
