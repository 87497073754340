import { useState } from 'react';
import PropTypes from 'prop-types';

import { Stack, Avatar, Popover, TableRow, MenuItem, TableCell, Typography, IconButton, Button } from '@mui/material';

import Iconify from 'component/iconify/iconify';
import Label from 'component/label/label';
import { formatNumberWithCommas } from 'component/Assets/FakeData';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { IMAGE_URL } from 'config.js';

// ----------------------------------------------------------------------

export default function UserTableRow({
  selected,
  name,
  avatarUrl,
  company,
  role,
  salary,
  status,
  handleModalService,
  targetId,
  services,
  all,
  handleUpdateWorker,
  handleViewWorker
}) {
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell sx={{ maxWidth: 20 }}>
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar alt={name} src={avatarUrl ? `${IMAGE_URL}${avatarUrl}` : ''} />
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">{role}</TableCell>
        <TableCell>{company}</TableCell>
        <TableCell align="center">{(services && services?.length) || 0}</TableCell>
        <TableCell>{`${formatNumberWithCommas(salary) || 0}Rwf`}</TableCell>
        <TableCell>
          <Label color={(status !== 'active' && 'error') || 'success'}>{status === 'active' ? 'Active' : 'Inactive'}</Label>
        </TableCell>
      </TableRow>
      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 140 }
        }}
      >
        <MenuItem
          onClick={() => {
            handleViewWorker(all);
            handleCloseMenu();
          }}
        >
          <Iconify icon="eva:eye-fill" sx={{ mr: 2 }} />
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleUpdateWorker(all);
            handleCloseMenu();
          }}
        >
          <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={handleCloseMenu} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleModalService({ targetId, services });
            handleCloseMenu();
          }}
          sx={{ color: 'success.main' }}
        >
          <MiscellaneousServicesIcon sx={{ mr: 2 }} />
          Services
        </MenuItem>
      </Popover>
    </>
  );
}
export function PaymentTableRow({ selected, id, name, avatarUrl, company, salary, all, handleUpdateWorker, handleViewWorker }) {
  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell component="th" scope="row" padding="none">
          {id}
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar alt={name} src={avatarUrl ? `${IMAGE_URL}${avatarUrl}` : ''} />
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>{`${formatNumberWithCommas(salary) || 0}Rwf`}</TableCell>
        <TableCell>{`${formatNumberWithCommas(company) || 0}Rwf`}</TableCell>
        <TableCell>
          <Button size="small" variant="outlined" sx={{ mr: 1, display: 'inline' }} onClick={() => handleViewWorker(all)}>
            View More
          </Button>
          <Button size="small" variant="outlined" onClick={() => handleUpdateWorker(all)}>
            Pay
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

UserTableRow.propTypes = {
  avatarUrl: PropTypes.any,
  company: PropTypes.any,
  handleClick: PropTypes.func,
  isVerified: PropTypes.any,
  name: PropTypes.any,
  role: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.string
};
