import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import useAuth from 'context/useAuth';
import AuthenticationRoutes from './AuthenticationRoutes';

export default function ThemeRoutes() {
  const { user } = useAuth();

  const mainRoutes = MainRoutes(user?.type, user?.role);

  return useRoutes([mainRoutes, AuthenticationRoutes]);
}
