import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';

// COMPANY API endpoints
export const companyApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCompanies: builder.mutation({
      query: (values) => {
        const data = new FormData();
        data.append('name', values?.name);
        data.append('owner', values?.fullnames);
        data.append('locations', values?.location);
        data.append('email', values?.email);
        data.append('phone', values?.phoneNumber);
        data.append('companyType', values?.businessType);
        data.append('password', values?.password);
        data.append('tin', values?.tin);
        data.append('login_email', values?.username);
        data.append('role', '1');
        return {
          method: 'POST',
          url: 'company/create',
          body: data,
          formData: true
        };
      },
      invalidatesTags: ['companies'],
      overrideExisting: true
    }),
    companyApplication: builder.mutation({
      query: (values) => {
        const data = new FormData();
        data.append('name', values?.name);
        data.append('owner', values?.fullnames);
        data.append('locations', values?.location);
        data.append('email', values?.email);
        data.append('phone', values?.phoneNumber);
        data.append('companyType', values?.businessType);
        data.append('password', values?.password);
        data.append('tin', values?.tin);
        data.append('login_email', values?.username);
        data.append('role', '1');
        return {
          method: 'POST',
          url: 'company/application',
          body: data,
          formData: true
        };
      },
      invalidatesTags: ['companies'],
      overrideExisting: true
    }),
    createGenerateCompanySubscription: builder.mutation({
      query: (data) => {
        return {
          url: 'company/generate-token',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['companies'],
      overrideExisting: true
    }),
    createCompanyUser: builder.mutation({
      query: (data) => {
        return {
          url: 'auth/signup',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['company-users'],
      overrideExisting: true
    }),
    createCompanySuppliers: builder.mutation({
      query: (data) => {
        return {
          url: 'company/supplier/create',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['company-supplier'],
      overrideExisting: true
    }),
    createUserPermission: builder.mutation({
      query: (data) => {
        return {
          url: 'user/create-permission',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['user-permission'],
      overrideExisting: true
    }),
    assignUserPermision: builder.mutation({
      query: (data) => {
        return {
          url: 'user/add-user-permission',
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['company-users'],
      overrideExisting: true
    }),
    updateCompanySuppliers: builder.mutation({
      query: (data) => {
        return {
          url: 'company/supplier/update',
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        };
      },
      invalidatesTags: ['company-supplier'],
      overrideExisting: true
    }),
    updateCompanyUser: builder.mutation({
      query: (data) => {
        return {
          url: `user/edit/${data?.user_id}`,
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        };
      },
      invalidatesTags: ['company-users'],
      overrideExisting: true
    }),
    updateCompany: builder.mutation({
      query: (data) => {
        return {
          url: '/company/update-info',
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        };
      },
      invalidatesTags: ['companies'],
      overrideExisting: true
    }),
    updateApplication: builder.mutation({
      query: (data) => {
        return {
          url: '/company/update-application',
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        };
      },
      invalidatesTags: ['companies'],
      overrideExisting: true
    }),
    getAllCompanies: builder.query({
      query: () => 'company/all',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        response?.data?.rows && response?.data?.count > 0 ? response?.data?.rows.sort((a, b) => b.id - a.id) : [],
      providesTags: ['companies'],
      overrideExisting: true
    }),
    getAllApplicants: builder.query({
      query: () => 'company/all-application',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        response?.data?.rows && response?.data?.count > 0 ? response?.data?.rows.sort((a, b) => b.id - a.id) : [],
      providesTags: ['companies'],
      overrideExisting: true
    }),
    getCompany: builder.query({
      query: ({ id }) => `company/single/${id}`,
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      overrideExisting: true
    }),
    deletePermission: builder.query({
      query: (id) => `user/remove-permission/${id}`,
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      overrideExisting: true,
      invalidatesTags: ['user-permission']
    }),
    getAllUserPermission: builder.query({
      query: () => 'user/permissions',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        response?.data?.rows && response?.data?.count > 0 ? response?.data?.rows.sort((a, b) => b.id - a.id) : [],
      invalidatesTags: ['user-permission'],
      overrideExisting: true
    }),
    getCurrentPrice: builder.query({
      query: () => 'shop/product/get-current-price',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      overrideExisting: true
    }),
    updateCompanyEbm: builder.mutation({
      query: (data) => {
        return {
          url: 'company/update-ebm',
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        };
      },
      invalidatesTags: ['company-users'],
      overrideExisting: true
    }),
    getCompanyUsers: builder.query({
      query: ({ limit = 100, skip = 0, q = '' }) => `company/business-users?limit=${limit}&skip=${skip}&q=${q}`
    }),
    logoutUser: builder.query({
      query: ({ company = 0, user = 0 }) => `company/business-logout?userId=${user}`
    }),
    defaultPassword: builder.mutation({
      query: (data) => {
        return {
          url: 'user/reset-secret',
          method: 'post',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        };
      }
    }),
    getAllCompanyUsers: builder.query({
      query: () => 'user/all',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        response?.data?.rows && response?.data?.count > 0 ? response?.data?.rows.sort((a, b) => b.id - a.id) : [],
      providesTags: ['company-users'],
      overrideExisting: true
    }),
    getAllCompanySupplier: builder.query({
      query: () => 'company/supplier/all?limit=100',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        response?.data?.rows && response?.data?.count > 0 ? response?.data?.rows.sort((a, b) => b.id - a.id) : [],
      providesTags: ['company-supplier'],
      overrideExisting: true
    })
  })
});

export const {
  useGetCompanyUsersQuery,
  useLazyGetCompanyQuery,
  useLazyLogoutUserQuery,
  useDefaultPasswordMutation,
  useGetCurrentPriceQuery,
  useGetAllCompaniesQuery,
  useGetAllApplicantsQuery,
  useUpdateCompanyMutation,
  useCreateCompaniesMutation,
  useGetAllCompanyUsersQuery,
  useLazyGetCompanyUsersQuery,
  useUpdateCompanyEbmMutation,
  useGetAllUserPermissionQuery,
  useUpdateCompanyUserMutation,
  useCreateCompanyUserMutation,
  useLazyDeletePermissionQuery,
  useUpdateApplicationMutation,
  useCompanyApplicationMutation,
  useGetAllCompanySupplierQuery,
  useAssignUserPermisionMutation,
  useCreateUserPermissionMutation,
  useCreateCompanySuppliersMutation,
  useUpdateCompanySuppliersMutation,
  useCreateGenerateCompanySubscriptionMutation
} = companyApi;

// Company slice
const companySlice = createSlice({
  name: 'company',
  initialState: { company: [], myCompany: {} },
  reducers: {
    setMyCompany: (state, { payload }) => {
      state.myCompany = payload;
    }
  },
  extraReducers: (builder) => {}
});

export const campanyReducer = companySlice.reducer;
export const { setMyCompany } = companySlice.actions;

export default companySlice;
