import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';
import dayjs from 'dayjs';
const today = dayjs().format('YYYY-MM-DD');

export const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardCount: builder.query({
      query: () => 'shop/totals/dashboard',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales'],
      overrideExisting: true
    }),
    getWeeklyChart: builder.query({
      query: () => 'shop/totals/dashboard/chart/weekly',
      refetchOnReconnect: true,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales'],
      overrideExisting: true
    }),
    getAnnualChart: builder.query({
      query: () => 'shop/totals/dashboard/chart/yearly',
      refetchOnReconnect: true,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales'],
      overrideExisting: true
    }),
    getProductChart: builder.query({
      query: () => 'shop/totals/dashboard/chart/top5-product',
      refetchOnReconnect: true,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales'],
      overrideExisting: true
    }),
    getToBeReOrderedList: builder.query({
      query: () => 'shop/totals/dashboard/listByName/toBeReOrdered',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        response?.data && response?.data?.length > 0 ? response?.data?.sort((a, b) => b.id - a.id) : []
    }),
    getToBeExpired: builder.query({
      query: () => 'shop/totals/dashboard/listByName/toBeExpired',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        response?.data && response?.data?.length > 0 ? response?.data?.sort((a, b) => b.id - a.id) : []
    }),

    // salon Dashboard Count

    getSalonDashboardCount: builder.query({
      query: () => `salon/get-totals?from=${today}&to=${today}&name=salonIncome`,
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales'],
      overrideExisting: true
    }),
    getWorkerCount: builder.query({
      query: (filterDates) => {
        let apiUrl = 'salon/get-totals?name=salonIncome';
        if (filterDates && filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        if (filterDates && filterDates?.user !== null) {
          apiUrl += `&workerId=${filterDates?.user}`;
        }
        return apiUrl;
      }, // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales'],
      overrideExisting: true
    }),
    getSingleWorkerIncomeCount: builder.query({
      query: (filterDates) => {
        let apiUrl = 'salon/get-totals?name=workerIncome';
        if (filterDates?.from && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates.from}&to=${filterDates.to}`;
        }
        return apiUrl;
      },
      refetchOnReconnect: true,
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales'],
      overrideExisting: true
    })
  })
});

export const {
  useGetWeeklyChartQuery,
  useGetAnnualChartQuery,
  useGetProductChartQuery,
  useGetDashboardCountQuery,
  useGetToBeExpiredQuery,
  useGetToBeReOrderedListQuery,
  useGetSalonDashboardCountQuery,
  useLazyGetWorkerCountQuery,
  useLazyGetSingleWorkerIncomeCountQuery
} = dashboardApi;

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    dashboard: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(dashboardApi.endpoints.getDashboardCount.matchFulfilled, (state, action) => {
      // console.log(action.payload.data);
    });
  }
});

export const campanyReducer = dashboardSlice.reducer;

export default dashboardSlice;
