import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Typography } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { styled } from 'styled-components';
import Searchbar from './SearchBar';
import { generateRowsWithAutoIncrementedId } from './Assets/FakeData';

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-sortIcon': {
    opacity: 1,
    color: 'white'
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: 'rgba(18, 9, 88, 1)',
    color: 'white'
  },
  '& .MuiDataGrid-menuIconButton': {
    opacity: 1,
    color: 'white'
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: '#F4F2FF'
  },
  '& .MuiGrid-root': {
    border: '2px solid green'
  },
  '& .MuiDataGrid-cell.centered-cell': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .MuiDataGrid-cell.margin-cell': {
    paddingLeft: '3rem'
  }
}));

const DataTableWithTitle = ({ title, columns, rows, searchable = false, download = false, height = 500, hideFooter = false }) => {
  const handleSearchSubmit = (searchTerm) => {
    // Handle the search logic or perform actions with the search term.
    console.log(`Search term submitted: ${searchTerm}`);
  };

  const autoIncrementedRows = generateRowsWithAutoIncrementedId(rows);

  return (
    <div style={{ width: '100% !important', overflowX: 'auto !important' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        {searchable && <Searchbar onSubmit={handleSearchSubmit} />}
        {download && <Button startIcon={<GetAppIcon />} style={{ color: 'rgba(0, 0, 0, 1)' }}></Button>}
      </div>
      <div style={{ height }}>
        <StyledDataGrid
          rows={autoIncrementedRows}
          columns={columns.map((column) => ({
            ...column
          }))}
          pageSize={8}
          hideFooter={hideFooter}
          rowsPerPageOptions={[10]}
          disableRowSelectionOnClick={true}
        />
      </div>
    </div>
  );
};

export default DataTableWithTitle;
