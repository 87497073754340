import React from 'react';
import { makeStyles } from '@mui/styles';
import TitleWithAddButton from 'component/Title/Title';
import DataTableWithTitle from 'component/DataTable';
import { Form, Field, Formik } from 'formik';
import { Typography, Grid, Box, Card, Container, Checkbox, FormControlLabel } from '@mui/material';
import { CustomInput } from 'component/inputs/TextInput';
import CustomNumberInput from 'component/inputs/NumberInput';
import { CustomDatePicker } from 'component/inputs/DatePicker';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { CustomTextArea } from 'component/inputs/TextArea';
import * as Yup from 'yup';
import { useCreateExpenseMutation, useGetAllExpenseQuery } from 'store/features/slices/expenseSlice';
import ErrorMessageResponse from 'component/ErrorMessage/ErrorMessageResponse';
import LoadingButton from 'component/Loader/LoadingButton';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';

function ExpenseReport() {
  const { data: expenseList = [], isLoading: expenseLoading } = useGetAllExpenseQuery();
  const [createExpense, { isLoading, isError, error, isSuccess }] = useCreateExpenseMutation();
  const [showError, setShowError] = React.useState(false);
  const toggleShowError = () => setShowError(!showError);
  const classes = useStyles();
  const theme = useTheme();

  const columns = [
    { field: '_id', headerName: '#' },
    { field: 'expenseDate', headerName: 'Date', flex: 1 },
    { field: 'expenseName', headerName: 'Expense', flex: 1 },
    { field: 'expenseRequester', headerName: 'Requester', flex: 1 },
    { field: 'billNumber', headerName: 'Bill No.', flex: 1 },
    { field: 'expenseAmount', headerName: 'Amount', flex: 1 },
    { field: 'vatTotal', headerName: 'VAT', flex: 1 },
    { field: 'comment', headerName: 'Comment', flex: 1 }
  ];

  function ExpenseForm() {
    return (
      <Formik
        initialValues={{
          comment: '',
          vatTotal: '0',
          addVat: false,
          billNumber: '',
          expenseName: '',
          expenseAmount: '',
          expenseRequester: '',
          expenseDate: dayjs().format('YYYY-MM-DD')
        }}
        validationSchema={Yup.object().shape({
          expenseName: Yup.string().required('Name is required!'),
          expenseAmount: Yup.string().required('Amouns are required!'),
          expenseRequester: Yup.string().required('Requester is required!'),
          expenseDate: Yup.string().required('Date is required!')
        })}
        onSubmit={async (values, actions) => {
          try {
            if (values.addVat) values.vatTotal = values.expenseAmount * 0.18;

            const result = await createExpense(values).unwrap();
            // actions.resetForm();
            actions.setSubmitting(false);
            notifications.show({
              id: 'expense',
              withCloseButton: true,
              autoClose: 3000,
              title: 'Expense',
              message: 'Expense Added Successfully!',
              icon: <IconX />,
              loading: false
            });
          } catch (err) {
            actions.setSubmitting(false);
            toggleShowError(true);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Grid item xs={12}>
                <Box marginBottom="1rem">
                  <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
                    Expense Details
                  </Typography>
                </Box>

                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <CustomInput name="expenseName" label="Expense" placeholder="Expense Name" readOnly={false} withAsterisk={true} />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomNumberInput name="expenseAmount" label="Amount" placeholder="expense Amount" withAsterisk={true} />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInput
                      readOnly={false}
                      label="Requester"
                      withAsterisk={true}
                      name="expenseRequester"
                      placeholder="expense requester"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomDatePicker name="expenseDate" label="Date" placeholder="expense Date" withAsterisk={true} />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInput name="billNumber" label="Bill Number" placeholder="Bill Number" readOnly={false} />
                  </Grid>
                  <Grid item xs={6} my="auto" textAlign="center">
                    <Field name="addVat">
                      {({ field, form }) => <FormControlLabel control={<Checkbox {...field} />} label="Add VAT" />}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextArea name="comment" label="Comment" placeholder="comment ...." />
                  </Grid>
                </Grid>
              </Grid>

              {showError && !isLoading && isError && !isSubmitting && (
                <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                  <ErrorMessageResponse>{error?.data?.message || error.message || 'An error occurred'}</ErrorMessageResponse>
                </Grid>
              )}
              <Grid container marginTop={2}>
                <LoadingButton
                  sx={{
                    backgroundColor: theme.palette.primary.primary,
                    color: 'white'
                  }}
                  loading={isSubmitting}
                  fullWidth={true}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return (
    <Container maxWidth="xl">
      <TitleWithAddButton title="Expense Report" showAddButton={true} addButtonLabel="Add Expense" form={<ExpenseForm />} />

      <Card>
        <DataTableWithTitle columns={columns} rows={expenseList} searchable={false} />
      </Card>
    </Container>
  );
}

export default ExpenseReport;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0'
  },
  card: {
    backgroundColor: '#2196F3',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '2rem',
    color: '#fff',
    textAlign: 'center'
  }
}));
