import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "./useAuth";

const PrivateRoute = ({ element, allowedRoles, allowedTypes }) => {
	const { user } = useAuth();

	// Check if the user is logged in
	if (!user) {
		// Redirect to login if not logged in
		return <Navigate to="/login" replace />;
	}

	// Check if the user's role is allowed
	if (allowedRoles && !allowedRoles.includes(user.role)) {
		// Redirect to a forbidden page or home page
		return <Navigate to="/forbidden" replace />;
	}

	// Check if the user's type is allowed
	if (allowedTypes && !allowedTypes.includes(user.type)) {
		// Redirect to a forbidden page or home page
		return <Navigate to="/forbidden" replace />;
	}

	// Render the component if all checks pass
	return element;
};

export default PrivateRoute;
