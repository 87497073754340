import { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import { Container } from '@mui/system';
import Divider from '@mui/material/Divider';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { Modal, PasswordInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import * as Yup from 'yup';
import { Field, Form, Formik, useFormikContext } from 'formik';

import Scrollbar from 'component/scrollbar/scrollbar';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';

import TableNoData from '../table-no-data';
import UserTableRow from '../user-table-row';
import UserTableHead from '../user-table-head';
import TableEmptyRows from '../table-empty-rows';
import { emptyRows, applyFilter, getComparator } from '../utils';
import { useTheme } from '@mui/material/styles';
import LoadingButton from 'component/Loader/LoadingButton';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import ErrorMessageResponse from 'component/ErrorMessage/ErrorMessageResponse';
import { CustomInput } from 'component/inputs/TextInput';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import {
  useCreateClosingMutation,
  useGetSalesMoneyQuery,
  useLazyGetIncomeReportQuery,
  useLazyGetMoneyAllocationQuery
} from 'store/features/slices/stockClosingSlice';
import CustomNumberInput from 'component/inputs/NumberInput';
import { CustomTextArea } from 'component/inputs/TextArea';
import { RenderSkeletons } from 'component/Assets/FakeData';
import NoDataFound from 'component/Assets/NoDataFound';
import UserTableToolbar from '../user-table-toolbar';

export default function IncomeReportPage() {
  const theme = useTheme();
  const [opened, { open, close }] = useDisclosure(false);
  const [showError, setShowError] = useState(false);
  const toggleShowError = () => setShowError(!showError);
  const [createClosing, { isLoading, isError, error, isSuccess }] = useCreateClosingMutation();
  const [fetchData, { data: IncomeReportList = [], isLoading: IncomeReportLoading, isFetching }] = useLazyGetIncomeReportQuery();

  const [fetchMoneyAllocation, { data: moneyAllocation = {} }] = useLazyGetMoneyAllocationQuery();

  const { data: salesMoney = 0 } = useGetSalesMoneyQuery();

  const [filterDates, setFilterDates] = useState({ from: '', to: '', type: 'system' });

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = IncomeReportList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const paymentMethodDisplayTitles = {
    cashInHand: 'Cash',
    loan: 'Loan',
    bankCheque: 'Cheque',
    mobileMoney: 'MOMO',
    bankCard: 'Card'
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = IncomeReportList;

  const notFound = !dataFiltered.length && !!filterName;

  const HandlePriceChange = () => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
      const hasPositivePaymentMethod = Object.values(values.paymentMethod).some((amount) => Number(amount) > 0);

      if (hasPositivePaymentMethod) {
        const totalPaymentMethodAmount = Object.values(values.paymentMethod).reduce((total, amount) => total + Number(amount), 0);
        const softMoney = values.softMoney;
        const difference = totalPaymentMethodAmount - softMoney;
        setFieldValue('balance', difference);
      }
    }, [values.paymentMethod, values.softMoney, setFieldValue]);

    return null;
  };

  const handleFilterByDates = () => {
    if (filterDates?.from !== '' && filterDates?.to !== '') {
      fetchMoneyAllocation(filterDates);
      fetchData(filterDates);
    } else {
      console.log("Please select both 'From' and 'To' dates before searching.");
    }
  };

  useEffect(() => {
    fetchMoneyAllocation();
    fetchData(filterDates);
  }, []);

  return (
    <Container maxWidth="xl">
      <Modal
        opened={opened}
        onClose={() => {
          close();
        }}
        size="xl"
        centered
        closeOnClickOutside={false}
      >
        <Box p={4}>
          <Formik
            initialValues={{
              paymentMethodTitle: {
                cashInHand: false,
                loan: false,
                bankCheque: false,
                mobileMoney: false,
                bankCard: false
              },
              paymentMethod: {
                cashInHand: 0,
                loan: 0,
                bankCheque: 0,
                mobileMoney: 0,
                bankCard: 0
              },
              secret: 'password',
              softMoney: salesMoney,
              comment: '',
              balance: ''
            }}
            validationSchema={Yup.object().shape({})}
            onSubmit={async (values, actions) => {
              try {
                const result = await createClosing(values).unwrap();
                actions.resetForm();
                actions.setSubmitting(false);
                close();
                notifications.show({
                  id: 'closing',
                  withCloseButton: true,
                  autoClose: 3000,
                  title: 'Stock',
                  message: `Stock Closed Successfully!`,
                  icon: <IconX />,
                  loading: false
                });
              } catch (err) {
                actions.setSubmitting(false);
                toggleShowError(true);
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, errors, values, setFieldValue }) => {
              return (
                <Form>
                  <Grid item xs={12}>
                    <Box marginBottom="1rem">
                      <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
                        Close Closing Details
                      </Typography>
                    </Box>

                    <Grid container spacing={1} mb={3}>
                      {Object.entries(values.paymentMethodTitle).map(([method, isSelected]) => (
                        <Grid item xs={6} sm={2} key={method}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isSelected}
                                onChange={(event) => {
                                  setFieldValue(`paymentMethodTitle.${method}`, event.target.checked);
                                  setFieldValue(`paymentMethod.${method}`, event.target.checked ? values.paymentMethod[method] || 0 : 0);
                                }}
                              />
                            }
                            label={paymentMethodDisplayTitles[method]}
                          />
                          {isSelected && (
                            <>
                              <CustomNumberInput
                                name={`paymentMethod.${method}`}
                                label={`Amount`}
                                placeholder={` ${paymentMethodDisplayTitles[method]} Amount`}
                                withAsterisk={true}
                              />
                              {errors.paymentMethod && errors.paymentMethod[method] && (
                                <div style={{ color: 'red' }}>{errors.paymentMethod[method]}</div>
                              )}
                            </>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <CustomNumberInput name="softMoney" label="Soft Money" placeholder="System money" readOnly={true} />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomInput name="balance" label="Balance" placeholder="Balance" readOnly={true} />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextArea name="comment" label="Comment" placeholder="comment ...." />
                      </Grid>
                    </Grid>
                  </Grid>

                  {showError && !isLoading && isError && !isSubmitting && (
                    <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                      <ErrorMessageResponse>{error?.data?.message || error.message || 'An error occurred'}</ErrorMessageResponse>
                    </Grid>
                  )}
                  <HandlePriceChange />
                  <Grid container marginTop={2}>
                    <LoadingButton
                      sx={{ backgroundColor: theme.palette.primary.primary, color: 'white' }}
                      loading={isSubmitting}
                      fullWidth={true}
                      done={false}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
          System Closing Report
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: theme.palette.primary.primary, color: 'white' }}
          startIcon={<DoDisturbOffIcon />}
          onClick={() => open()}
        >
          Close Stock
        </Button>
      </Stack>
      {IncomeReportLoading ? (
        RenderSkeletons()
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={8.5}>
            <Card>
              <UserTableToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                filterDates={filterDates}
                setFilterDates={setFilterDates}
                handleFilterByDates={handleFilterByDates}
                isFetching={isFetching}
              />

              {IncomeReportList && IncomeReportList?.length > 0 ? (
                <>
                  <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }}>
                      <Table sx={{ minWidth: 800 }}>
                        <UserTableHead
                          order={order}
                          orderBy={orderBy}
                          rowCount={IncomeReportList.length}
                          numSelected={selected.length}
                          onRequestSort={handleSort}
                          onSelectAllClick={handleSelectAllClick}
                          headLabel={[
                            { id: 'incomeDate', label: 'Date' },
                            { id: 'hardMoney', label: 'Hard Money' },
                            { id: 'softMoney', label: 'Soft Money' },
                            { id: 'balance', label: 'status' },
                            { id: 'comment', label: 'Observation' },
                            { id: '' }
                          ]}
                        />
                        <TableBody>
                          {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <UserTableRow
                              key={row.id}
                              incomeDate={row.incomeDate}
                              hardMoney={row.hardMoney}
                              softMoney={row.softMoney}
                              balance={row.balance}
                              comment={row.comment}
                              selected={selected.indexOf(row.name) !== -1}
                              handleClick={(event) => handleClick(event, row.name)}
                            />
                          ))}

                          <TableEmptyRows height={77} emptyRows={emptyRows(page, rowsPerPage, IncomeReportList.length)} />

                          {notFound && <TableNoData query={filterName} />}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    page={page}
                    component="div"
                    count={IncomeReportList.length}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <NoDataFound message="No Closing Report Found!" />
              )}
            </Card>
          </Grid>
          <Grid item md={3.5}>
            <Card>
              <CardContent>
                <Typography variant="h5">Pending Money Allocation</Typography>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
                  <span>Mobile Money</span>
                  <span>{moneyAllocation?.data?.['Mobile Money'] || 0}</span>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <span>Cash in hand</span>
                  <span>{moneyAllocation?.data?.['Cash in hand'] || 0}</span>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <span>Loan</span>
                  <span>{moneyAllocation?.data?.['Loan'] || 0}</span>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <span>Bank card</span>
                  <span>{moneyAllocation?.data?.['Bank card'] || 0}</span>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <span>Bank cheque</span>
                  <span>{moneyAllocation?.data?.['Bank cheque'] || 0}</span>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
