// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/simplebar-react/dist/simplebar.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-11lq3yg-MuiGrid-root {
  display: inherit !important;
}

.m-60c222c7 {
  z-index: 99999 !important;
}

.mantine-Popover-dropdown {
  z-index: 9999999 !important;
}

.error-checkbox .MuiSvgIcon-root {
  fill: red;
}

.primary-checkbox .MuiSvgIcon-root {
  fill: inherit;
}

.search-bar-container {
  padding-top: 20vh;
  width: 40%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
}

.css-nn2b4o-MuiTableCell-root {
  padding: 16px 18px !important;
}

.link {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

@media print {
  body {
    visibility: hidden;
    display: none;
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE;IACE,kBAAkB;IAClB,aAAa;EACf;AACF;;AAEA;EACE,aAAa;AACf","sourcesContent":["@import 'simplebar-react/dist/simplebar.min.css';\n\n.css-11lq3yg-MuiGrid-root {\n  display: inherit !important;\n}\n\n.m-60c222c7 {\n  z-index: 99999 !important;\n}\n\n.mantine-Popover-dropdown {\n  z-index: 9999999 !important;\n}\n\n.error-checkbox .MuiSvgIcon-root {\n  fill: red;\n}\n\n.primary-checkbox .MuiSvgIcon-root {\n  fill: inherit;\n}\n\n.search-bar-container {\n  padding-top: 20vh;\n  width: 40%;\n  margin: auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  min-width: 200px;\n}\n\n.css-nn2b4o-MuiTableCell-root {\n  padding: 16px 18px !important;\n}\n\n.link {\n  text-decoration: none;\n  cursor: pointer;\n  color: inherit;\n}\n\n@media print {\n  body {\n    visibility: hidden;\n    display: none;\n  }\n}\n\niframe#webpack-dev-server-client-overlay {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
