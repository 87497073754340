import * as React from 'react';
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import { styled } from 'styled-components';
import { Button, Typography } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import Searchbar from './SearchBar';

const StyledDataGrid = styled(DataGridPro)(() => ({
  '& .MuiDataGrid-sortIcon': {
    opacity: 1,
    color: 'white'
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: 'rgba(18, 9, 88, 1)',
    color: 'white'
  },
  '& .MuiDataGrid-menuIconButton': {
    opacity: 1,
    color: 'white'
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: '#F4F2FF'
  }
}));

const DataGroupedTable = ({ title, rows, columns, searchable = false }) => {
  const handleSearchSubmit = (searchTerm) => {
    // Handle the search logic or perform actions with the search term.
    console.log(`Search term submitted: ${searchTerm}`);
  };

  const getTreeDataPaths = (row) => row.hierarchy;
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        {searchable && <Searchbar onSubmit={handleSearchSubmit} />}
        <Button startIcon={<GetAppIcon />} style={{ color: 'rgba(0, 0, 0, 1)' }}></Button>
      </div>
      <div style={{ height: 500 }}>
        <StyledDataGrid treeData rows={rows} columns={columns} getTreeDataPath={getTreeDataPaths} pageSize={8} autoPageSize={true} />
      </div>
    </div>
  );
};

export default DataGroupedTable;
