import React, { lazy } from 'react';
import PrivateRoute from 'context/PrivateRoute';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'component/Loadable';
import PurchaseReport from 'views/Reports/PurchaseReport';
import SalesReport from 'views/Reports/SalesReport';
import LoanReport from 'views/Reports/LoanReport';
import ProformaReport from 'views/Reports/ProformaReport';
import ExpenseReport from 'views/Reports/ExpenseReport';
import IncomeReport from 'views/Reports/IncomeReport';
import UserClosing from 'views/Reports/UserClosing';
import SellingBill from 'views/Bills/SellingBill';
import ProductList from 'views/Admin/ProductList';
import SalonServices from 'Salon/Admin/Services';
import { SalonWorkersView, SalonPayment } from 'Salon/Admin/user/view';

const DashboardDefault = Loadable(lazy(() => import('../views/Dashboard')));
const Purchases = Loadable(lazy(() => import('../views/Purchases')));
const Conditions = Loadable(lazy(() => import('../views/Conditions')));
const Production = Loadable(lazy(() => import('../views/Production')));
const CurrentPrice = Loadable(lazy(() => import('../views/CurrentPrice')));
const Sales = Loadable(lazy(() => import('../views/Sales')));
const Proforma = Loadable(lazy(() => import('../views/Proforma')));
const SalesBar = Loadable(lazy(() => import('../views/SalesBar')));
const Stock = Loadable(lazy(() => import('../views/Stock')));
const PurchaseBillReview = Loadable(lazy(() => import('../views/Bills/PurchaseBillReview')));
const UtilsTypography = Loadable(lazy(() => import('../views/Utils/Typography')));
const SamplePage = Loadable(lazy(() => import('../views/SamplePage')));
const ProductCategory = Loadable(lazy(() => import('../views/Admin/ProductCategory')));
const Producttype = Loadable(lazy(() => import('../views/Admin/ProductType')));
const UserAccount = Loadable(lazy(() => import('../views/Account')));
const EbmSettings = Loadable(lazy(() => import('../views/Admin/Settings')));
const CampanyUser = Loadable(lazy(() => import('../views/Admin/Users')));
const ServiceRevenue = Loadable(lazy(() => import('../views/Admin/ServiceRevenue')));
const ServiceRevenueReport = Loadable(lazy(() => import('../Salon/SalonReports/ServiceRevenueReport')));
const SalonWorkerPage = Loadable(lazy(() => import('../Salon/worker/WorkerPage')));
const SupplierView = Loadable(lazy(() => import('../views/Supplier/Supplier-view')));
const BusinessRegistration = Loadable(lazy(() => import('../views/SuperAdmin/BusinessRegistration')));
const CompanyBusinessView = Loadable(lazy(() => import('../views/SuperAdmin/BusinessView')));
const BusinessApplicationView = Loadable(lazy(() => import('../views/SuperAdmin/BusinessApplication')));
const UserPermission = Loadable(lazy(() => import('../views/SuperAdmin/UserPermission')));
const AllUser = Loadable(lazy(() => import('../views/SuperAdmin/AllUser')));

const MainRoutes = (type, role) => {
  const getDashboardElement = () => {
    if (type === 'shop' || type === 'bar') {
      return <Conditions />;
    }
    if (type === 'salon' && role === '2') {
      return <PrivateRoute element={<SalonWorkerPage />} allowedRoles={['1', '2']} allowedTypes={['salon']} />;
    }
    if (type === 'vendor' && role === '0') {
      return <PrivateRoute element={<CompanyBusinessView />} allowedRoles={['0']} allowedTypes={['vendor']} />;
    }
    return <PrivateRoute element={<DashboardDefault />} allowedRoles={['1', '2']} allowedTypes={['shop', 'salon']} />;
  };
  return {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        // element: <Conditions />
        element: getDashboardElement()
      },
      {
        path: '/dashboard',
        element: <PrivateRoute element={<DashboardDefault />} allowedRoles={['1', '2']} allowedTypes={['shop', 'salon', 'bar']} />
      },
      {
        path: '/salon/worker',
        element: <PrivateRoute element={<SalonWorkerPage />} allowedRoles={['1', '2']} allowedTypes={['salon']} />
      },
      {
        path: '/business',
        element: <PrivateRoute element={<CompanyBusinessView />} allowedRoles={['0']} allowedTypes={['vendor']} />
      },
      {
        path: '/business-application',
        element: <PrivateRoute element={<BusinessApplicationView />} allowedRoles={['0']} allowedTypes={['vendor']} />
      },
      {
        path: '/business-registration',
        element: <PrivateRoute element={<BusinessRegistration />} allowedRoles={['0']} allowedTypes={['vendor']} />
      },
      {
        path: '/user-permission',
        element: <PrivateRoute element={<UserPermission />} allowedRoles={['0']} allowedTypes={['vendor']} />
      },
      {
        path: '/all-user',
        element: <PrivateRoute element={<AllUser />} allowedRoles={['0']} allowedTypes={['vendor']} />
      },
      {
        path: '/utils/util-typography',
        element: <UtilsTypography />
      },
      {
        path: '/sample-page',
        element: <SamplePage />
      },
      {
        path: '/conditions',
        element: <Conditions />
      },
      {
        path: '/current-price',
        element: <CurrentPrice />
      },
      {
        path: '/production',
        element: <Production />
      },
      {
        path: '/user-closing',
        element: <UserClosing />
      },

      // Purchase Routes

      {
        path: '/purchases',
        element: <Purchases />
      },
      {
        path: '/purchases-bill-review',
        element: <PurchaseBillReview />
      },
      {
        path: '/sales-bill-review',
        element: <SellingBill />
      },
      {
        path: '/proforma',
        element: <PrivateRoute element={<Proforma />} allowedRoles={['1', '2']} allowedTypes={['shop', 'salon']} />
      },

      // Sales Route
      {
        path: '/sales',
        element: <PrivateRoute element={<Sales />} allowedRoles={['1', '2']} allowedTypes={['shop', 'salon']} />
      },
      {
        path: '/bar/sales',
        element: <PrivateRoute element={<SalesBar />} allowedRoles={['1', '2']} allowedTypes={['bar']} />
      },

      // Stock Route
      {
        path: '/stock',
        element: <Stock />
      },
      {
        path: '/supplier',
        element: <SupplierView />
      },

      // Report Boutique
      {
        path: '/report/purchase',
        element: <PurchaseReport />
      },
      {
        path: '/report/sales',
        element: <SalesReport />
      },
      {
        path: '/report/loan',
        element: <LoanReport />
      },
      {
        path: '/report/proforma',
        element: <ProformaReport />
      },
      {
        path: '/report/expense',
        element: <ExpenseReport />
      },
      {
        path: '/report/income',
        element: <IncomeReport />
      },

      //  SALON REPORTS

      {
        path: '/salon/report/service-revenue',
        element: <PrivateRoute element={<ServiceRevenueReport />} allowedRoles={['1']} allowedTypes={['salon']} />
      },

      // Admin Route
      {
        path: '/admin/productlist',
        element: <ProductList />
      },
      {
        path: '/admin/productcategory',
        element: <ProductCategory />
      },
      {
        path: '/admin/producttype',
        element: <Producttype />
      },
      {
        path: '/admin/users',
        element: <CampanyUser />
      },
      {
        path: '/admin/ebm-settings',
        element: <EbmSettings />
      },

      // User Account

      {
        path: '/account',
        element: <UserAccount />
      },

      // salon routes

      {
        path: '/salon/revenue',
        element: <PrivateRoute element={<ServiceRevenue />} allowedRoles={['1', '2']} allowedTypes={['salon']} />
      },

      {
        path: '/salon/payment',
        element: <PrivateRoute element={<SalonPayment />} allowedRoles={['1', '2']} allowedTypes={['salon']} />
      },

      {
        path: '/admin/services',
        element: <PrivateRoute element={<SalonServices />} allowedRoles={['1']} allowedTypes={['salon']} />
      },
      {
        path: '/admin/workers',
        element: <PrivateRoute element={<SalonWorkersView />} allowedRoles={['1']} allowedTypes={['salon']} />
      }
    ]
  };
};

export default MainRoutes;
