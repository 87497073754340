import { useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { Cancel } from '@mui/icons-material';
import { TableRow, TableCell, Typography, IconButton } from '@mui/material';
import Label from 'component/label/label';

// ----------------------------------------------------------------------

export default function ServiceRevenueTableRow({
  createdAt,
  worker,
  serviceName,
  client,
  workerPrice,
  payments,
  all,
  id,
  deleteRequest,
  deleted,
  comment,
  handleUpdateWorker
}) {
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" sx={{ textDecoration: deleted ? 'line-through' : 'inherit' }}>
        <TableCell component="th" scope="row" padding="none">
          {id}
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" noWrap>
            {dayjs(createdAt)?.format('DD/MM/YYYY HH:mm:ss') || null}
          </Typography>
        </TableCell>
        <TableCell>{worker}</TableCell>
        <TableCell>{client}</TableCell>
        <TableCell>{serviceName}</TableCell>
        <TableCell>
          {payments?.map((payment) => (
            <span key={payment?.amount}>
              {payment?.amount} ({payment?.method?.substring(0, 1)?.toUpperCase()})
            </span>
          ))}
        </TableCell>
        <TableCell>{workerPrice}</TableCell>
        <TableCell>
          {deleteRequest ? (
            <Label color="warning">Pending</Label>
          ) : (
            <IconButton size="small" onClick={() => handleUpdateWorker(all)}>
              <Cancel />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

ServiceRevenueTableRow.propTypes = {
  avatarUrl: PropTypes.any,
  company: PropTypes.any,
  handleClick: PropTypes.func,
  isVerified: PropTypes.any,
  name: PropTypes.any,
  role: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.string
};
