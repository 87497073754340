import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// assets
// import 'assets/scss/style.scss';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import 'simplebar-react/dist/simplebar.min.css';

// third party
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

// project import
import App from 'layout/App';
import reducer from 'store/reducer';
import * as serviceWorker from 'serviceWorker';
import 'index.css';
import { store } from 'store/store';
import { Notifications } from '@mantine/notifications';
import { AuthProvider } from 'context/AuthProvider';

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <MantineProvider>
          <Notifications position="top-right" zIndex={99999999} />
          <App />
        </MantineProvider>
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);

serviceWorker.unregister();
