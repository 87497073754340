import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Text } from '@mantine/core';
import { useTheme } from '@mui/material/styles';
import { Typography, Stack, Button } from '@mui/material';

function TitleWithAddButton({ title, showAddButton = true, addButtonLabel = 'Add New', form, mdsize = 'md' }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        {showAddButton && (
          <Button variant="contained" sx={{ backgroundColor: theme.palette.primary.primary, color: 'white' }} onClick={handleOpen}>
            {addButtonLabel}
          </Button>
        )}
      </Stack>

      <Modal opened={open} closeOnClickOutside={false} closeOnEscape={false} onClose={handleClose} size={`${mdsize}`} centered>
        {form}
      </Modal>
    </div>
  );
}

export default TitleWithAddButton;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  padding-left: 0;
`;
