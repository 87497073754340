import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { Card, Stack, Table, Tab, Container, TableBody, Box, Grid, Typography, TableContainer, TablePagination } from '@mui/material';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Scrollbar from 'component/scrollbar/scrollbar';
import TableNoData from '../table-no-data';
import { PaymentTableRow } from '../user-table-row';
import UserTableHead from '../user-table-head';
import TableEmptyRows from '../table-empty-rows';
import UserTableToolbar from '../user-table-toolbar';
import { emptyRows, applyFilter, getComparator } from '../utils';
import { useTheme } from '@mui/material/styles';
import LoadingButton from 'component/Loader/LoadingButton';
import ErrorMessageResponse from 'component/ErrorMessage/ErrorMessageResponse';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { CustomTextArea } from 'component/inputs/TextArea';
import CustomNumberInput from 'component/inputs/NumberInput';
import {
  useCreateWorkerMutation,
  useGetAllWorkerQuery,
  useLazyGetSalonPaymentsQuery,
  usePayWorkerMutation
} from 'store/features/slices/salon/workerSlice';
import { makeStyles } from '@mui/styles';
import { useAddServiceToEmployeeMutation, useGetAllServicesQuery } from 'store/features/slices/salon/serviceSlice';
import { RenderSkeletons, formatNumberWithCommas } from 'component/Assets/FakeData';
import ServiceReviewReportPage from 'sections/stock/view/ServiceRevenuePage';
import styled from 'styled-components';
import NoDataFound from 'component/Assets/NoDataFound';
import SalonWorkerPageTableToolbar from 'sections/stock/salon-worker-TableToolbar';
import { PaymentTableRow as PayTableRow } from 'sections/stock/salon-worker-TableRow';
import { useLazyGetAllEmployeeWorksQuery } from 'store/features/slices/salon/workerSlice';

export default function SalonPayment() {
  const theme = useTheme();
  const [value, setValue] = useState('1');
  const [opened] = useDisclosure(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [updateModal, setupdateModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(null);
  const [viewDetails, setViewDetails] = useState(null);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [showError, setShowError] = useState(false);
  const toggleShowError = () => setShowError(!showError);
  const { data: companyUserList = [], isLoading: workerLoading } = useGetAllWorkerQuery();
  const [createSalonWorkers] = useCreateWorkerMutation();
  const [payWorker, { isLoading: updateIsLoading, isError: UpdateIsError, error: UpdateError }] = usePayWorkerMutation();
  const [AddServiceToEmployee] = useAddServiceToEmployeeMutation();
  const { data: serviceList, isLoading: serviceIsLoading } = useGetAllServicesQuery();
  const [fetchPayment, { data: PaymentList = [], isLoading: PaymentLoading, isFetching }] = useLazyGetSalonPaymentsQuery();

  const today = dayjs().format('YYYY-MM-DD');
  const [filterDates, setFilterDates] = useState({ from: today, to: today, user: null });

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = companyUserList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: companyUserList,
    comparator: getComparator(order, orderBy),
    filterName
  });
  const handleModalService = (data) => {
    setSelectedWorker(data);
    setServiceModal(true);
  };
  const handleUpdateWorker = (data) => {
    setUpdateDetails(data);
    setupdateModal(true);
  };

  const handleViewWorker = (data) => {
    setViewDetails(data);
    setViewModal(true);
  };
  const notFound = !dataFiltered.length && !!filterName;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const paymentFiltered = applyFilter({
    inputData: PaymentList,
    comparator: getComparator(order, orderBy),
    filterName
  });

  const handleFilterByDates = () => {
    if (filterDates?.from !== null && filterDates?.to !== null) {
      fetchPayment(filterDates);
    } else {
      console.log("Please select both 'From' and 'To' dates before searching.");
    }
  };

  useEffect(() => {
    fetchPayment(filterDates);
  }, []);

  return (
    <Container>
      <Modal
        opened={updateModal}
        onClose={() => {
          setupdateModal(false);
          setUpdateDetails(null);
          toggleShowError(false);
        }}
        size="md"
        centered
        closeOnClickOutside={false}
      >
        <Box p={2} pt={0}>
          <Formik
            initialValues={{
              salonWorkerId: updateDetails?.id,
              amount: updateDetails?.unpaid,
              paymentType: 'SERVICE-PAYMENT',
              comment: ''
            }}
            validationSchema={Yup.object().shape({
              amount: Yup.string().required('Amount is Required')
            })}
            onSubmit={async (values, actions) => {
              try {
                const result = await payWorker(values).unwrap();
                actions.resetForm();
                actions.setSubmitting(false);
                setupdateModal(false);
                setUpdateDetails(null);
                toggleShowError(false);
                notifications.show({
                  id: 'worker-payment',
                  withCloseButton: true,
                  autoClose: 3000,
                  title: 'Payment',
                  message: `Worker payment saved successfully!`,
                  icon: <IconX />,
                  loading: false
                });
              } catch (err) {
                actions.setSubmitting(false);
                toggleShowError(true);
              }
            }}
          >
            {({ handleSubmit, isSubmitting, setFieldValue }) => (
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Box marginBottom="1rem">
                  <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
                    Pay {updateDetails?.firstName} {updateDetails?.lastName}
                  </Typography>
                </Box>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <CustomNumberInput
                      name="amount"
                      label="Amount"
                      placeholder="Enter Amount"
                      hideControls={true}
                      withAsterisk={true}
                      prefix=" Rwf"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextArea name="comment" label="Comment" placeholder="Leave a comment" multiline fullWidth rows={3} />
                  </Grid>
                </Grid>

                {showError && !updateIsLoading && UpdateIsError && !isSubmitting && (
                  <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                    <ErrorMessageResponse>{UpdateError?.data?.message || UpdateError.message || 'An error occurred'}</ErrorMessageResponse>
                  </Grid>
                )}

                <Box mt={2}>
                  <LoadingButton
                    sx={{ backgroundColor: theme.palette.primary.primary, color: 'white' }}
                    loading={isSubmitting}
                    done={false}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </LoadingButton>
                </Box>

                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Modal
        opened={viewModal}
        onClose={() => {
          setViewModal(false);
          setViewDetails(null);
          toggleShowError(false);
        }}
        size={900}
        centered
        closeOnClickOutside={false}
      >
        <Stack>
          <Typography variant="h5" ml={3} color={theme.palette.primary.primary} gutterBottom>
            {viewDetails?.firstName} {viewDetails?.lastName} Service Revenue
          </Typography>
          <ServiceReviewReportPage workerId={viewDetails?.id} />
        </Stack>
      </Modal>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="Worker Service tabs">
            <Tab label="Worker Payment" value="1" />
            <Tab label="Payment History" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          {workerLoading ? (
            RenderSkeletons()
          ) : (
            <Card>
              <UserTableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

              {companyUserList && companyUserList?.length > 0 ? (
                <>
                  <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }}>
                      <Table sx={{ minWidth: 800 }}>
                        <UserTableHead
                          order={order}
                          orderBy={orderBy}
                          rowCount={companyUserList.length}
                          numSelected={selected.length}
                          onRequestSort={handleSort}
                          onSelectAllClick={handleSelectAllClick}
                          headLabel={[
                            { id: 'id', label: '#' },
                            { id: 'name', label: 'Name' },
                            { id: 'salary', label: 'Salary' },
                            { id: 'company', label: 'Unpaid' },
                            { id: 'status', label: 'Action' }
                          ]}
                        />
                        <TableBody>
                          {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, id) => (
                            <PaymentTableRow
                              id={id + 1}
                              key={row.id}
                              targetId={row.id}
                              name={row.fullName}
                              role={row.role}
                              status={row.status}
                              company={row.unpaid}
                              salary={row.salary}
                              avatarUrl={row.image}
                              services={row.services}
                              all={row}
                              selected={selected.indexOf(row.name) !== -1}
                              handleClick={(event) => handleClick(event, row.name)}
                              handleModalService={handleModalService}
                              handleUpdateWorker={handleUpdateWorker}
                              handleViewWorker={handleViewWorker}
                            />
                          ))}

                          <TableEmptyRows height={77} emptyRows={emptyRows(page, rowsPerPage, companyUserList.length)} />

                          {notFound && <TableNoData query={filterName} />}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    page={page}
                    component="div"
                    count={companyUserList.length}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <NoDataFound message="No Data Found!" />
              )}
            </Card>
          )}
        </TabPanel>
        <TabPanel value="2">
          {PaymentLoading ? (
            RenderSkeletons()
          ) : (
            <Card>
              <SalonWorkerPageTableToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                filterDates={filterDates}
                setFilterDates={setFilterDates}
                handleFilterByDates={handleFilterByDates}
                isFetching={isFetching}
              />

              {PaymentList && PaymentList?.length > 0 ? (
                <>
                  <Scrollbar>
                    <TableContainer sx={{ overflow: 'unset' }}>
                      <Table sx={{ minWidth: 800 }}>
                        <UserTableHead
                          order={order}
                          orderBy={orderBy}
                          rowCount={PaymentList.length}
                          numSelected={selected.length}
                          onRequestSort={handleSort}
                          onSelectAllClick={handleSelectAllClick}
                          headLabel={[
                            { id: 'id', label: '#' },
                            { id: 'createdAt', label: 'Date' },
                            { id: 'amount', label: 'Amount ' },
                            { id: 'comment', label: 'Comment' },
                            { id: 'salonWorkerId', label: 'Worker' }
                          ]}
                        />
                        <TableBody>
                          {paymentFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, id) => (
                            <PayTableRow
                              id={id + 1}
                              key={row.id}
                              createdAt={row.createdAt}
                              worker={row?.salon_worker?.fullName}
                              amount={row.amount}
                              comment={row.comment}
                              selected={selected.indexOf(row?.name) !== -1}
                              handleClick={(event) => handleClick(event, row.name)}
                            />
                          ))}

                          <TableEmptyRows height={77} emptyRows={emptyRows(page, rowsPerPage, PaymentList.length)} />

                          {notFound && <TableNoData query={filterName} />}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    page={page}
                    component="div"
                    count={PaymentList.length}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <NoDataFound message="No Data Found!" />
              )}
            </Card>
          )}
        </TabPanel>
      </TabContext>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0'
  },
  card: {
    backgroundColor: '#2196F3',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '2rem',
    color: '#fff',
    textAlign: 'center'
  },
  customCard: {
    backgroundColor: '#f5f5f5', // Light Gray
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '20px',
    margin: '1rem 0',
    transition: 'box-shadow 0.3s ease-in-out',

    '&:hover': {
      boxShadow: '0 8px 12px rgba(0, 0, 0, 0.2)'
    }
  },

  errorColor: {
    color: theme.palette.error.main
  },
  noWrap: {
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap'
    }
  }
}));

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  flex: 1;
`;
const UserDetailsContainer = styled.div`
  flex: 1;
  padding: 20px;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserDetailsField = styled.div`
  margin-bottom: 10px;
`;

const AvatarContainer = styled.div`
  margin-bottom: 10px;
  text-align: center;
`;

const DetailField = ({ label, value }) => (
  <UserDetailsField>
    <Typography variant="body1">
      <strong>{label}:</strong> {value}
    </Typography>
  </UserDetailsField>
);
