import React from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery, AppBar, Box, Toolbar, createTheme } from '@mui/material';

// project import
import { drawerWidth } from 'config.js';
import Header from './Header';
import Sidebar from './Sidebar';
import RequireAuth from 'context/RequireAuth';

// custom style

const Main = styled((props) => <main {...props} />)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  [theme.breakpoints.up('md')]: {
    marginLeft: -drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`
  }
}));

const OutletDiv = styled((props) => <div {...props} />)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  React.useEffect(() => {
    setDrawerOpen(matchUpMd);
  }, [matchUpMd]);

  return (
    <RequireAuth>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <AppBar
          position="fixed"
          sx={{
            zIndex: 1200
          }}
        >
          <Toolbar>
            <Header drawerOpen={drawerOpen} drawerToggle={handleDrawerToggle} />
          </Toolbar>
        </AppBar>
        <Sidebar drawerOpen={drawerOpen} drawerToggle={handleDrawerToggle} />
        <Main
          style={{
            ...(drawerOpen && {
              transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
              }),
              marginLeft: 0,
              marginRight: 'inherit'
            })
          }}
        >
          <Box sx={theme.mixins.toolbar} />
          <OutletDiv>
            <Outlet />
          </OutletDiv>
        </Main>
      </Box>
    </RequireAuth>
  );
};

export default MainLayout;
