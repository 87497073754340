import React, { useState, useEffect } from 'react';

// material-ui
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// third-party
import { useDispatch, useSelector } from 'react-redux';

// project import
import theme from 'themes';
import Routes from 'routes/index';
import NavigationScroll from './NavigationScroll';
import SessionEndedAlert from 'sections/session';
import { useNavigate } from 'react-router';
import { clearUserAndToken } from 'store/features/slices/userSlice';
import useAuth from 'context/useAuth';
import { setMyCompany } from 'store/features/slices/companySlice';

const App = () => {
  const [sessionEndedAlertOpen, setSessionEndedAlertOpen] = useState(false);
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkInactivity = () => {
    const expireTime = localStorage.getItem('expire');

    if (expireTime < Date.now()) {
      handleLogout();
      setSessionEndedAlertOpen(true);
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + 30 * 60000;
    localStorage.setItem('expire', expireTime);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkInactivity();
    }, 3 * 60000);

    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    dispatch(clearUserAndToken());
    dispatch(setMyCompany({}));
    setAuth(null);
    window.location.assign('http://blive.rw');
  };

  useEffect(() => {
    updateExpireTime();

    window.addEventListener('click', updateExpireTime);
    window.addEventListener('keypress', updateExpireTime);
    window.addEventListener('scroll', updateExpireTime);
    window.addEventListener('mousemove', updateExpireTime);

    return () => {
      window.removeEventListener('click', updateExpireTime);
      window.removeEventListener('keypress', updateExpireTime);
      window.removeEventListener('scroll', updateExpireTime);
      window.removeEventListener('mousemove', updateExpireTime);
    };
  }, []);

  const customization = useSelector((state) => state.existing.customization);

  return (
    <>
      <NavigationScroll>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme(customization)}>
            <CssBaseline />
            <Routes />
          </ThemeProvider>
        </StyledEngineProvider>
      </NavigationScroll>

      <SessionEndedAlert open={sessionEndedAlertOpen} onClose={() => setSessionEndedAlertOpen(false)} />
    </>
  );
};

export default App;
