import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { List, Typography } from '@mui/material';

// project import
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import useAuth from 'context/useAuth';

// ==============================|| NAVGROUP ||============================== //

const NavGroup = ({ item }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const userState = useSelector((state) => state.user.info);

  const filteredChildren = item.children.filter((child) => {
    if (child.allowedRoles && !child.allowedRoles.includes(user.role)) {
      return false;
    }
    if (child.allowedTypes && !child.allowedTypes.includes(user.type)) {
      return false;
    }
    if (user.role == '2')
      switch (child.id) {
        case 'dashboard':
          return user?.type != 'salon';
        case 'conditions':
          return userState?.permissions?.includes('VIEW CONDITION');
        case 'sales':
          return userState?.permissions?.includes('VIEW SALES');
        case 'proforma':
          return userState?.permissions?.includes('VIEW PROFORMA');
        case 'purchases':
          return userState?.permissions?.includes('VIEW PURCHASE');
        case 'stock':
          return userState?.permissions?.includes('VIEW STOCK');
        case 'supplier':
          return userState?.permissions?.includes('VIEW SUPPLIER');
        case 'current-price':
          return userState?.permissions?.includes('VIEW CURRENT PRICE');
        case 'production':
          return userState?.permissions?.includes('VIEW PRODUCTION');
        case 'user-closing':
          return userState?.permissions?.includes('VIEW USER CLOSING');
        case 'revenue':
          return userState?.permissions?.includes('VIEW SERVICE REVENUE');
        case 'payments':
          return userState?.permissions?.includes('VIEW PAYMENT');
        case 'report':
          return (
            userState?.permissions?.includes('VIEW PURCHASE REPORT') ||
            userState?.permissions?.includes('VIEW PROFORMA REPORT') ||
            userState?.permissions?.includes('VIEW CLOSING REPORT') ||
            userState?.permissions?.includes('VIEW EXPENSE REPORT') ||
            userState?.permissions?.includes('VIEW SALES REPORT') ||
            userState?.permissions?.includes('VIEW LOAN REPORT')
          );
        case 'admin':
          return (
            userState?.permissions?.includes('VIEW PRODUCT CATEGORY') ||
            userState?.permissions?.includes('VIEW EBM SETTINGS') ||
            userState?.permissions?.includes('VIEW PRODUCT LIST') ||
            userState?.permissions?.includes('VIEW SUPPLIER') ||
            userState?.permissions?.includes('VIEW USERS')
          );
      }
    return true;
  });

  const items = filteredChildren?.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case 'item':
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
          {item.title}
          {item.caption && (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
              {item.caption}
            </Typography>
          )}
        </Typography>
      }
    >
      {items}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
  children: PropTypes.object,
  title: PropTypes.string,
  caption: PropTypes.string
};

export default NavGroup;
