import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';

export const purchaseApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createPurchase: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/purchase/create',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['purchase'],
      overrideExisting: true
    }),
    getAllPurchases: builder.query({
      query: (filterDates) => {
        let apiUrl = 'shop/purchase/all?limit=300&skip=0';
        if (filterDates && filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        if ((filterDates && filterDates?.user !== null) || undefined) {
          apiUrl += `&supplierId=${filterDates?.user}`;
        }
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['purchase'],
      overrideExisting: true
    }),
    getPurchaseTotals: builder.query({
      query: (filterDates) => {
        let apiUrl = 'shop/totals/salesTotal?name=purchaseReport';
        if (filterDates && filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['purchase'],
      overrideExisting: true
    })
  })
});

export const { useCreatePurchaseMutation, useGetAllPurchasesQuery, useLazyGetAllPurchasesQuery, useLazyGetPurchaseTotalsQuery } =
  purchaseApi;

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState: {
    purchase: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(purchaseApi.endpoints.createPurchase.matchFulfilled, (state, action) => {
      // console.log(action.payload.data);
    });
  }
});

export const purchaseReducer = purchaseSlice.reducer;

export default purchaseSlice;
