import PropTypes from 'prop-types';

import { IconButton, Stack, Tooltip, TableRow, TableCell, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

import dayjs from 'dayjs';

// ----------------------------------------------------------------------

export function SalonWorkerPageTableRow({
  createdAt,
  id,
  worker,
  client,
  serviceName,
  servicePrice,
  workerPrice,
  all,
  deleteRequest,
  deleted,
  comment
}) {
  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" sx={{ textDecoration: deleted ? 'line-through' : 'inherit' }}>
        <TableCell component="th" scope="row" padding="none">
          {id}
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {dayjs(createdAt)?.format('DD/MM/YYYY HH:mm:ss') || null}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>{worker}</TableCell>
        <TableCell>{client}</TableCell>
        <TableCell>{serviceName}</TableCell>
        <TableCell>{servicePrice}</TableCell>
        <TableCell>{workerPrice}</TableCell>
        <TableCell>
          {deleteRequest && (
            <Tooltip title="Cancel Service">
              <IconButton size="small" onClick={() => {}}>
                <CheckCircle />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

export function PaymentTableRow({ createdAt, id, amount, comment, worker }) {
  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox">
        <TableCell component="th" scope="row" padding="none">
          {id}
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {dayjs(createdAt)?.format('DD/MM/YYYY HH:mm:ss') || null}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>{amount}</TableCell>
        <TableCell>{comment}</TableCell>
        <TableCell>{worker}</TableCell>
      </TableRow>
    </>
  );
}

SalonWorkerPageTableRow.propTypes = {
  avatarUrl: PropTypes.any,
  company: PropTypes.any,
  handleClick: PropTypes.func,
  isVerified: PropTypes.any,
  name: PropTypes.any,
  role: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.string
};
