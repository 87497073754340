import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import Iconify from 'component/iconify/iconify';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ServiceRevenueTableToolbar({
  numSelected,
  filterName,
  onFilterName,
  filterDates,
  setFilterDates,
  handleFilterByDates,
  isFetching = false,
  WorkerList
}) {
  const theme = useTheme();

  const handleChange = (event) => {
    setFilterDates((prevFilterDates) => ({
      ...prevFilterDates,
      user: event.target.value
    }));
  };
  return (
    <Toolbar
      sx={{
        height: 96,
        display: 'flex',
        justifyContent: 'space-between',
        p: (theme) => theme.spacing(0, 1, 0, 3),
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <DatePicker
                label="From"
                format="DD/MM/YYYY"
                value={filterDates.from ? dayjs(filterDates.from) : null}
                onChange={(date) => setFilterDates({ ...filterDates, from: date ? dayjs(date).format('YYYY-MM-DD') : null })}
              />
            </Grid>
            <Grid item>
              <DatePicker
                label="To"
                format="DD/MM/YYYY"
                value={filterDates.to ? dayjs(filterDates.to) : null}
                onChange={(date) => setFilterDates({ ...filterDates, to: date ? dayjs(date).format('YYYY-MM-DD') : null })}
              />
            </Grid>
            {WorkerList?.length ? (
              <Grid item>
                <FormControl sx={{ m: 1, minWidth: 100 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">Worker</InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={filterDates.user || ''}
                    onChange={handleChange}
                    autoWidth
                    label="Select User"
                  >
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    {WorkerList?.map((worker) => (
                      <MenuItem key={worker.id} value={worker.id}>
                        {worker.fullName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              ''
            )}
            <Grid item>
              <Tooltip title="Filter List">
                <IconButton
                  onClick={handleFilterByDates}
                  disabled={isFetching && filterDates.from !== null && filterDates.to !== null}
                  sx={{
                    background: 'white',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)'
                  }}
                >
                  {isFetching && filterDates.from !== null && filterDates.to !== null ? (
                    <CircularProgress size={15} sx={{ color: theme.palette.primary.primary }} />
                  ) : (
                    <Iconify icon="eva:search-fill" sx={{ color: 'gray' }} />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

ServiceRevenueTableToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};
