import React, { useState } from 'react';
import { Field } from 'formik';
import { Select } from '@mantine/core';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffe8e8;
  border-width: 1px;
  border-color: #d8000c;
  border-radius: 4px;
  padding: 10px;
`;

const ErrorText = styled.div`
  color: #d8000c;
  font-size: 14px;
  font-weight: bold;
`;

const SelectServices = ({ label, name, placeholder, options, cleared, withAsterisk, disabled }) => {
  const [selectedItem, setSelectedItem] = useState('');
  const [resetKey, setResetKey] = useState(0);

  return (
    <Field name={name}>
      {({ field, form }) => {
        const handleChange = (option) => {
          const selectedOption = options.find((opt) => opt.serviceName === option);
          const itemAlreadyExists = form.values.services.some((services) => services.salonServiceId === selectedOption.id);

          if (selectedOption && !itemAlreadyExists) {
            const newItem = {
              salonServiceId: selectedOption.id,
              serviceName: selectedOption.serviceName,
              servicePrice: selectedOption.servicePrice,
              workerPrice: selectedOption.workerPrice || 0
            };
            form.setFieldValue(name, [...form.values.services, newItem]);
            setSelectedItem('');
          }

          setResetKey((prevKey) => prevKey + 1);
        };

        return (
          <Select
            key={resetKey}
            label={label}
            placeholder={placeholder}
            data={options.map((option) => option.serviceName)}
            value={selectedItem}
            onChange={(value) => {
              handleChange(value);
            }}
            searchable
            disabled={disabled}
            clearable={true}
            withAsterisk={withAsterisk}
          />
        );
      }}
    </Field>
  );
};

export default SelectServices;
