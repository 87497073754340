import { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { Modal, PasswordInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import * as Yup from 'yup';
import { Field, Form, Formik, useFormikContext } from 'formik';

import Scrollbar from 'component/scrollbar/scrollbar';

import TableNoData from '../table-no-data';
import UserTableRow from '../user-table-row';
import UserTableHead from '../user-table-head';
import TableEmptyRows from '../table-empty-rows';
import { emptyRows, applyFilter, getComparator } from '../utils';
import { useTheme } from '@mui/material/styles';
import LoadingButton from 'component/Loader/LoadingButton';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import ErrorMessageResponse from 'component/ErrorMessage/ErrorMessageResponse';
import { CustomInput } from 'component/inputs/TextInput';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import {
  useCancelPaymentMutation,
  useCreateClosingMutation,
  useGetSalesMoneyQuery,
  useLazyGetIncomeReportQuery
} from 'store/features/slices/stockClosingSlice';
import CustomNumberInput from 'component/inputs/NumberInput';
import { CustomTextArea } from 'component/inputs/TextArea';
import { InfoContainer, RenderSkeletons, Title, Value, formatNumberWithCommas } from 'component/Assets/FakeData';
import NoDataFound from 'component/Assets/NoDataFound';
import UserTableToolbar from '../user-table-toolbar';
import ServiceRevenueTableToolbar from '../service-toolbar';
import { useGetAllWorkerQuery, useLazyGetAllEmployeeWorksQuery } from 'store/features/slices/salon/workerSlice';
import ServiceRevenueTableRow from '../service-table-row';
import dayjs from 'dayjs';
import { useLazyGetWorkerCountQuery } from 'store/features/slices/dashboardSlice';
import { useSelector } from 'react-redux';

export default function ServiceReviewReportPage({ workerId = 0 }) {
  const theme = useTheme();
  const [updateModal, setupdateModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [showError, setShowError] = useState(false);
  const { role } = useSelector((state) => state.user.info);
  const toggleShowError = () => setShowError(!showError);
  const [createClosing, { isLoading, isError, error, isSuccess }] = useCreateClosingMutation();
  const [cancelPayment, { isLoading: updateIsLoading, isError: UpdateIsError, error: UpdateError }] = useCancelPaymentMutation();
  const [fetchData, { data: IncomeReportList = [], isLoading: IncomeReportLoading, isFetching }] = useLazyGetAllEmployeeWorksQuery();
  const [fetchWorkerCounts, { data = {}, isLoading: WorkerCountLoading }] = useLazyGetWorkerCountQuery();
  const { data: WorkerList = [], isLoading: workerLoading } = useGetAllWorkerQuery();

  const today = dayjs().format('YYYY-MM-DD');

  const { data: salesMoney = 0 } = useGetSalesMoneyQuery();

  const [filterDates, setFilterDates] = useState({ from: today, to: today, user: workerId ? workerId : null });

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = IncomeReportList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleUpdateWorker = (data) => {
    setUpdateDetails(data);
    setupdateModal(true);
  };

  const dataFiltered = applyFilter({
    inputData: IncomeReportList,
    comparator: getComparator(order, orderBy),
    filterName
  });

  const notFound = !dataFiltered.length && !!filterName;

  const handleFilterByDates = () => {
    if (filterDates?.user || (filterDates?.from !== null && filterDates?.to !== null)) {
      fetchData(filterDates);
      fetchWorkerCounts(filterDates);
    } else {
      console.log("Please select both 'From' and 'To' dates before searching.");
    }
  };

  const renderInfo = (data) => {
    return (
      <>
        <InfoContainer>
          <Title>Total Service Price:</Title>
          <Value>{`${formatNumberWithCommas(data?.totalPrice || 0)} Rwf`}</Value>
        </InfoContainer>

        <InfoContainer>
          <Title>Total Worker Price:</Title>
          <Value>{`${formatNumberWithCommas(data?.totalWorkerPrice || 0)} Rwf`}</Value>
        </InfoContainer>
      </>
    );
  };

  useEffect(() => {
    fetchData(filterDates);
    fetchWorkerCounts(filterDates);
  }, []);

  return (
    <Container>
      <Modal
        opened={updateModal}
        onClose={() => {
          setupdateModal(false);
          setUpdateDetails(null);
        }}
        size="md"
        centered
        closeOnClickOutside={false}
      >
        <Box p={2} pt={0}>
          <Formik
            initialValues={{
              id: updateDetails?.id,
              salonWorkerId: updateDetails?.workerId,
              comment: ''
            }}
            validationSchema={Yup.object().shape({
              comment: Yup.string().required('Comment is Required')
            })}
            onSubmit={async (values, actions) => {
              try {
                const result = await cancelPayment(values).unwrap();
                actions.resetForm();
                actions.setSubmitting(false);
                setupdateModal(false);
                setUpdateDetails(null);
                toggleShowError(false);
                notifications.show({
                  id: 'worker-service',
                  withCloseButton: true,
                  autoClose: 3000,
                  title: 'Request Cancellation',
                  message: `Worker service cancel request!`,
                  icon: <IconX />,
                  loading: false
                });
              } catch (err) {
                actions.setSubmitting(false);
                toggleShowError(true);
              }
            }}
          >
            {({ handleSubmit, isSubmitting, setFieldValue }) => (
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Box marginBottom="1rem">
                  <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
                    Cancel Service
                  </Typography>
                  {updateDetails?.serviceName} by {updateDetails?.workerName} to {updateDetails?.clientName}
                </Box>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <CustomTextArea name="comment" label="Comment" placeholder="Leave a comment" multiline fullWidth rows={3} />
                  </Grid>
                </Grid>

                {showError && !updateIsLoading && UpdateIsError && !isSubmitting && (
                  <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                    <ErrorMessageResponse>{UpdateError?.data?.message || UpdateError.message || 'An error occurred'}</ErrorMessageResponse>
                  </Grid>
                )}

                <Box mt={2}>
                  <LoadingButton
                    sx={{ backgroundColor: theme.palette.primary.primary, color: 'white' }}
                    loading={isSubmitting}
                    done={false}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </LoadingButton>
                </Box>

                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      {workerId == 0 ? (
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Service Revenue Report
          </Typography>
        </Stack>
      ) : (
        ''
      )}
      {IncomeReportLoading ? (
        RenderSkeletons()
      ) : (
        <Card>
          <ServiceRevenueTableToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            filterDates={filterDates}
            setFilterDates={setFilterDates}
            handleFilterByDates={handleFilterByDates}
            isFetching={isFetching}
            WorkerList={workerId ? [] : WorkerList}
          />

          {IncomeReportList && IncomeReportList?.length > 0 ? (
            <>
              <Scrollbar>
                <TableContainer sx={{ overflow: 'unset' }}>
                  <Table sx={{ minWidth: 800 }}>
                    <UserTableHead
                      order={order}
                      orderBy={orderBy}
                      rowCount={IncomeReportList.length}
                      numSelected={selected.length}
                      onRequestSort={handleSort}
                      onSelectAllClick={handleSelectAllClick}
                      headLabel={[
                        { id: 'actionDate', label: '#' },
                        { id: 'createdAt', label: 'Date' },
                        { id: 'worker', label: 'Worker' },
                        { id: 'clientName', label: 'Client' },
                        { id: 'serviceName', label: 'Service' },
                        { id: 'servicePrice', label: 'Payment' },
                        { id: 'workerPrice', label: 'Worker Price' },
                        { id: 'serviceId', label: '' }
                      ]}
                    />
                    <TableBody>
                      {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, id) => (
                        <ServiceRevenueTableRow
                          id={id + 1}
                          key={row.serviceId}
                          createdAt={row.createdAt}
                          worker={row.workerName}
                          deleteRequest={row.deleteRequest}
                          comment={row.comment}
                          deleted={row.deleted}
                          client={row.clientName}
                          serviceName={row.serviceName}
                          servicePrice={row.servicePrice}
                          workerPrice={row.workerPrice}
                          payments={row.payments}
                          selected={selected.indexOf(row?.name) !== -1}
                          handleClick={(event) => handleClick(event, row.name)}
                          all={row}
                          handleUpdateWorker={handleUpdateWorker}
                        />
                      ))}

                      <TableEmptyRows height={77} emptyRows={emptyRows(page, rowsPerPage, IncomeReportList.length)} />

                      {notFound && <TableNoData query={filterName} />}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                page={page}
                component="div"
                count={IncomeReportList.length}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <NoDataFound message="No Service Revenue Data Found!" />
          )}
        </Card>
      )}

      <Grid container direction="row" justifyContent="Center" alignItems="flex-end" mt={3}>
        {renderInfo(data)}
      </Grid>
    </Container>
  );
}
