import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from 'store/features/api/apiSlice';

export const serviceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createService: builder.mutation({
      query: (data) => {
        return {
          url: 'salon/create-service',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['services', 'worker']
    }),
    UpdateStockServiceStatus: builder.mutation({
      query: (data) => {
        return {
          url: 'salon/update-service',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['services', 'worker']
    }),

    UpdateStockServiceDetails: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/product/update-stock',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['services', 'worker'],
      overrideExisting: true
    }),

    AddServiceToEmployee: builder.mutation({
      query: (data) => {
        return {
          url: 'salon/add-service-to-employee',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['services', 'worker']
    }),

    getAllServices: builder.query({
      query: () => 'salon/get-services',
      refetchOnReconnect: true,
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        (response.data && response.data.count > 0 && response?.data?.rows.sort((a, b) => b.id - a.id)) || [],
      providesTags: ['services']
    })
  })
});

export const { useGetAllServicesQuery, useCreateServiceMutation, useAddServiceToEmployeeMutation } = serviceApi;

const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    service: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(serviceApi.endpoints.getStock.matchFulfilled, (state, action) => {});
  }
});

export const serviceReducer = serviceSlice.reducer;

export default serviceSlice;
