import React from 'react';
import { lazy } from 'react';

// project imports
import Loadable from 'component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Payment from 'component/payment';
import ForbiddenPage from 'sections/Forbidden';

const AuthLogin = Loadable(lazy(() => import('../views/Login')));
const Forgot = Loadable(lazy(() => import('../views/Login/Forgot')));
const AuthRegister = Loadable(lazy(() => import('../views/Register/guest')));

// ==============================|| AUTHENTICATION ROUTES ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin />
    },
    {
      path: '/register',
      element: <AuthRegister />
    },
    {
      path: '/forgot',
      element: <Forgot />
    },
    {
      path: '/payment',
      element: <Payment />
    },
    {
      path: '/forbidden',
      element: <ForbiddenPage />
    }
  ]
};

export default AuthenticationRoutes;
