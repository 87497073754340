import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Formik } from "formik";
import {
	Box,
	Card,
	FormHelperText,
	MenuItem,
	TableCell,
	TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useTheme } from "@mui/material/styles";
import DataTableWithTitle from "component/DataTable";
import LoadingButton from "component/Loader/LoadingButton";
import ErrorMessageResponse from "component/ErrorMessage/ErrorMessageResponse";
import {
	useCreateServiceMutation,
	useGetAllServicesQuery,
} from "store/features/slices/salon/serviceSlice";
import { Label } from "@mui/icons-material";

function SalonServices() {
	const classes = useStyles();
	const theme = useTheme();
	const [showError, setShowError] = React.useState(false);
	const [createService, { isLoading, isError, error, isSuccess }] =
		useCreateServiceMutation();
	const { data: serviceList, isLoading: serviceIsLoading } =
		useGetAllServicesQuery();
	const toggleShowError = () => setShowError(!showError);

	const columns = [
		{
			field: "_id",
			headerName: "#Index",
			flex: 1,
			cellClassName: "margin-cell",
		},
		{
			field: "serviceName",
			headerName: "Service Name",
			flex: 1,
		},
		{
			field: "servicePrice",
			headerName: "Service Price",
			flex: 1,
			renderCell: (params) => `${Number(params.value) || 0} Rwf `,
		},
		{
			field: "status",
			headerName: "Service Status",
			flex: 1,
			renderCell: renderStatusCell,
		},
	];
	return (
		<Grid item xs={12}>
			<Grid container spacing={3}>
				<Grid item lg={8} xs={12}>
					<Card sx={{ height: "100%", padding: "20px" }}>
						<DataTableWithTitle
							title="Salon Servcices"
							columns={columns}
							rows={serviceList || []}
							searchable={false}
							download={false}
						/>
					</Card>
				</Grid>
				<Grid item lg={4} xs={12}>
					<div className={classes.customCard}>
						<Typography variant="h6" className={classes.title}>
							Salon Service
						</Typography>
						<Typography variant="body1" className={classes.Subtitle}>
							Add New Product Service
						</Typography>
						<Formik
							initialValues={{
								serviceName: "",
								servicePrice: "",
							}}
							validationSchema={Yup.object().shape({
								serviceName: Yup.string().required("Service Name is required"),
								servicePrice: Yup.string().required(
									"Service Price is required",
								),
							})}
							onSubmit={async (values, actions) => {
								try {
									const result = await createService(values).unwrap();
									actions.resetForm();
									actions.setSubmitting(false);
								} catch (err) {
									actions.setSubmitting(false);
									toggleShowError();
								} finally {
									actions.setSubmitting(false);
								}
							}}
						>
							{({
								errors,
								handleBlur,
								handleChange,
								handleSubmit,
								isSubmitting,
								touched,
								values,
							}) => (
								<form noValidate onSubmit={handleSubmit}>
									<TextField
										error={Boolean(touched.serviceName && errors.serviceName)}
										fullWidth
										helperText={touched.serviceName && errors.serviceName}
										label="Service Name"
										margin="normal"
										name="serviceName"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.serviceName}
										variant="outlined"
									/>

									<TextField
										error={Boolean(touched.servicePrice && errors.servicePrice)}
										fullWidth
										helperText={touched.servicePrice && errors.servicePrice}
										label="Service Price"
										margin="normal"
										name="servicePrice"
										onBlur={handleBlur}
										type="number"
										onChange={handleChange}
										value={values.servicePrice}
										variant="outlined"
									/>

									{errors.submit && (
										<Box mt={3}>
											<FormHelperText error>{errors.submit}</FormHelperText>
										</Box>
									)}
									<Box mt={2}>
										<LoadingButton
											sx={{
												backgroundColor: theme.palette.primary.primary,
												color: "white",
											}}
											loading={isSubmitting}
											done={false}
											fullWidth
											size="large"
											type="submit"
											variant="contained"
										>
											Submit
										</LoadingButton>
									</Box>
									{showError && !isLoading && isError && (
										<Grid
											container
											spacing={0}
											direction="row"
											justifyContent="center"
											alignItems="center"
											marginTop={2}
										>
											<ErrorMessageResponse>
												{error?.data?.message ||
													error.message ||
													"An error occurred"}
											</ErrorMessageResponse>
										</Grid>
									)}
								</form>
							)}
						</Formik>
					</div>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles(() => ({
	customCard: {
		backgroundColor: "#F9F9F9",
		boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
		borderRadius: "10px",
		padding: "20px",
	},
	title: {
		fontSize: "16px",
		color: "#333",
	},

	Subtitle: {
		fontSize: "14px",
		color: "#333",
		marginBottom: "1rem",
	},
	formField: {
		marginBottom: "20px",
	},
	submitButton: {
		backgroundColor: "background: rgba(18, 9, 88, 1)",
		color: "#FFF",
		padding: "10px 20px",
		border: "none",
		borderRadius: "5px",
		cursor: "pointer",
		fontSize: "1rem",
		transition: "background-color 0.3s",
	},

	successCell: {
		backgroundColor: "green", // Change this to your desired success color
		color: "white", // Change this to your desired text color
	},
	inactiveCell: {
		backgroundColor: "yellow", // Change this to your desired inactive color
		color: "black", // Change this to your desired text color
	},

	errorCell: {
		backgroundColor: "red", // Change this to your desired error color
		color: "white", // Change this to your desired text color
	},
}));

const renderStatusCell = (params) => {
	const status = params.value.toLowerCase();
	const cellStyle = {
		backgroundColor:
			status === "active"
				? "rgba(0, 172, 105, 0.16)"
				: "rgba(255, 65, 58, 0.16)",
		color: status === "active" ? "#007849" : "#a20e00",
		padding: "0 6px",
		borderRadius: "4px",
		fontSize: " 0.75rem",
		fontWeight: 700,
	};

	return <div style={cellStyle}>{params?.value?.toUpperCase()}</div>;
};

export default SalonServices;
