import React, { useState } from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { ActionIcon, Avatar, Input, Modal, NumberInput, PasswordInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import * as Yup from 'yup';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Iconify from 'component/iconify/iconify';
import Scrollbar from 'component/scrollbar/scrollbar';
import TableNoData from '../table-no-data';
import UserTableRow from '../user-table-row';
import UserTableHead from '../user-table-head';
import TableEmptyRows from '../table-empty-rows';
import UserTableToolbar from '../user-table-toolbar';
import { emptyRows, applyFilter, getComparator } from '../utils';
import { useTheme } from '@mui/material/styles';
import LoadingButton from 'component/Loader/LoadingButton';
import { Box, Grid, IconButton } from '@mui/material';
import ErrorMessageResponse from 'component/ErrorMessage/ErrorMessageResponse';
import { CustomInput } from 'component/inputs/TextInput';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import CustomMultiSelect from 'component/inputs/MultiSelect';
import CustomNumberInput from 'component/inputs/NumberInput';
import { useCreateWorkerMutation, useGetAllWorkerQuery, useUpdateWorkerDetailsMutation } from 'store/features/slices/salon/workerSlice';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectServices from 'component/inputs/SelectService';
import { useAddServiceToEmployeeMutation, useGetAllServicesQuery } from 'store/features/slices/salon/serviceSlice';
import { RenderSkeletons, convertRolesToArray, formatNumberWithCommas } from 'component/Assets/FakeData';
import { IMAGE_URL } from 'config.js';
import styled from 'styled-components';
import NoDataFound from 'component/Assets/NoDataFound';

export default function UserPage() {
  const classes = useStyles();
  const theme = useTheme();
  const [opened, { open, close }] = useDisclosure(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [updateModal, setupdateModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(null);
  const [viewDetails, setViewDetails] = useState(null);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [showError, setShowError] = useState(false);
  const toggleShowError = () => setShowError(!showError);
  const { data: companyUserList = [], isLoading: workerLoading } = useGetAllWorkerQuery();
  const [createSalonWorkers, { isLoading, isError, error, isSuccess }] = useCreateWorkerMutation();
  const [UpdateSalonWorkers, { isLoading: updateIsLoading, isError: UpdateIsError, error: UpdateError }] = useUpdateWorkerDetailsMutation();
  const [
    AddServiceToEmployee,
    { isLoading: AddServiceToEmployeeIsLaoding, isError: AddServiceToEmployeeIsError, error: AddServiceToEmployeeError }
  ] = useAddServiceToEmployeeMutation();
  const { data: serviceList, isLoading: serviceIsLoading } = useGetAllServicesQuery();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = companyUserList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: companyUserList,
    comparator: getComparator(order, orderBy),
    filterName
  });
  const handleModalService = (data) => {
    setSelectedWorker(data);
    setServiceModal(true);
  };
  const handleUpdateWorker = (data) => {
    setUpdateDetails(data);
    setupdateModal(true);
  };

  const handleViewWorker = (data) => {
    setViewDetails(data);
    setViewModal(true);
  };
  const notFound = !dataFiltered.length && !!filterName;

  return (
    <Container>
      <Modal
        opened={opened}
        onClose={() => {
          close();
        }}
        size="xl"
        centered
        closeOnClickOutside={false}
      >
        <Box p={4}>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              phone: '',
              payment: '',
              role: '',
              salary: '',
              address: '',
              image: null,
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required('First name is required'),
              lastName: Yup.string().required('Last Name is required'),
              role: Yup.array().min(1, 'At least one role is required').required('Role is required'),
              phone: Yup.string()
                .matches(/^\d{10}$/, 'Phone Number must be 10 digits')
                .required('Phone Number is required'),
              payment: Yup.string().required('payment period is required'),
              address: Yup.string().required('Address is Required')
            })}
            onSubmit={async (values, actions) => {
              try {
                const result = await createSalonWorkers(values).unwrap();
                actions.resetForm();
                actions.setSubmitting(false);
                close();
                notifications.show({
                  id: 'salon-worker',
                  withCloseButton: true,
                  autoClose: 3000,
                  title: 'Salon Worker Creation',
                  message: `Worker Created Successfully!`,
                  icon: <IconX />,
                  loading: false
                });
              } catch (err) {
                actions.setSubmitting(false);
                toggleShowError(true);
              }
            }}
          >
            {({ handleSubmit, isSubmitting, errors, setFieldValue, touched, values }) => (
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid item xs={12}>
                  <Box marginBottom="1rem">
                    <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
                      Worker Details
                    </Typography>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <label htmlFor="image" style={{ marginBottom: '0.5rem' }}>
                          <Field name="image">
                            {({ form }) => (
                              <Avatar
                                src={form.values.image ? URL.createObjectURL(form.values.image) : ''}
                                alt="Uploaded Image"
                                size={120}
                                style={{ border: '4px solid #fff', boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)' }}
                              />
                            )}
                          </Field>
                        </label>
                        <label htmlFor="image">
                          <Input
                            id="image"
                            name="image"
                            type="file"
                            accept="image/jpeg, image/png"
                            style={{ display: 'none' }}
                            onChange={(event) => {
                              const newImage = event.currentTarget.files[0];
                              if (newImage) {
                                setFieldValue('image', newImage);
                              }
                            }}
                          />
                          <IconButton component="span" color="primary" aria-label="upload">
                            <CloudUploadIcon />
                          </IconButton>
                        </label>
                        <ErrorMessage name="image" component="div" style={{ color: 'red' }} />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput
                        name="firstName"
                        label="First Name"
                        placeholder="Enter First Name"
                        readOnly={false}
                        withAsterisk={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput name="lastName" label="Last Name" placeholder="Enter Last Name" readOnly={false} withAsterisk={true} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomMultiSelect
                        name="role"
                        label="Role"
                        placeholder="Select Role"
                        options={[
                          { name: 'Hair Stylist', id: 'Hair Stylist' },
                          { name: 'Hair Barber', id: 'Hair Barber' },
                          { name: 'Esthetician', id: 'Esthetician' },
                          { name: 'Nail Technician', id: 'Nail Technician' },
                          { name: 'Makeup Artist', id: 'Makeup Artist' },
                          { name: 'Salon Manager', id: 'Salon Manager' },
                          { name: 'Receptionist', id: 'Receptionist' },
                          { name: 'Shampoo Technician', id: 'Shampoo Technician' },
                          { name: 'Shampoo Assistant', id: 'Shampoo Assistant' }
                        ]}
                        cleared={false}
                        withAsterisk={true}
                      />
                    </Grid>
                    <Grid item xs={6} m={0} p={0}>
                      <CustomInput
                        name="phone"
                        label="Phone Number"
                        placeholder="Enter Phone Number"
                        readOnly={false}
                        withAsterisk={true}
                      />
                    </Grid>

                    <Grid item xs={6} m={0} p={0}>
                      <CustomNumberInput
                        name="payment"
                        label="Payment Period(in Days)"
                        placeholder="Ex: 1 day , 15 days"
                        hideControls={false}
                        withAsterisk={true}
                        prefix=" Days"
                      />
                    </Grid>

                    <Grid item xs={6} m={0} p={0}>
                      <CustomNumberInput
                        name="salary"
                        label="Salary"
                        placeholder="Enter Salary"
                        hideControls={true}
                        withAsterisk={false}
                        prefix=" Rwf"
                      />
                    </Grid>

                    <Grid item xs={12} mb={0} pb={0}>
                      <CustomInput name="address" label="Home Address" placeholder="Enter Address" readOnly={false} withAsterisk={true} />
                    </Grid>

                    <Grid item xs={12} mt={2}>
                      <Typography variant="h5" gutterBottom mb={0}>
                        <strong> Account Details</strong>
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <CustomInput name="username" label="Username" placeholder="Enter username " readOnly={false} />
                    </Grid>

                    <Grid item xs={6}>
                      <Field name="password">
                        {({ field, form }) => (
                          <PasswordInput
                            label="Password"
                            placeholder="Enter password"
                            {...field}
                            error={form.touched['password'] && form.errors['password'] ? form.errors['password'] : false}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>

                {showError && !isLoading && isError && !isSubmitting && (
                  <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                    <ErrorMessageResponse>{error?.data?.message || error.message || 'An error occurred'}</ErrorMessageResponse>
                  </Grid>
                )}

                <Box mt={2}>
                  <LoadingButton
                    sx={{ backgroundColor: theme.palette.primary.primary, color: 'white' }}
                    loading={isSubmitting}
                    done={false}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    SUBMIT
                  </LoadingButton>
                </Box>

                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Modal
        opened={updateModal}
        onClose={() => {
          setupdateModal(false);
          setUpdateDetails(null);
          toggleShowError(false);
        }}
        size="xl"
        centered
        closeOnClickOutside={false}
      >
        <Box p={4}>
          <Formik
            initialValues={{
              workerId: updateDetails?.id,
              firstName: updateDetails?.firstName,
              lastName: updateDetails?.lastName,
              phone: updateDetails?.phone,
              payment: updateDetails?.paymentPeriod,
              role: updateDetails?.role ? convertRolesToArray(updateDetails?.role) : [],
              salary: updateDetails?.salary,
              address: updateDetails?.homeAddress,
              image: updateDetails?.image
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required('First name is required'),
              lastName: Yup.string().required('Last Name is required'),
              role: Yup.array().min(1, 'At least one role is required').required('Role is required'),
              phone: Yup.string()
                .matches(/^\d{10}$/, 'Phone Number must be 10 digits')
                .required('Phone Number is required'),
              payment: Yup.string().required('payment period is required'),
              salary: Yup.string().required('Salary is Required'),
              address: Yup.string().required('Address is Required')
            })}
            onSubmit={async (values, actions) => {
              try {
                const result = await UpdateSalonWorkers(values).unwrap();
                actions.resetForm();
                actions.setSubmitting(false);
                setupdateModal(false);
                setUpdateDetails(null);
                toggleShowError(false);
                notifications.show({
                  id: 'updatee-worker',
                  withCloseButton: true,
                  autoClose: 3000,
                  title: 'Salon Worker Update',
                  message: `Worker Updated Successfully!`,
                  icon: <IconX />,
                  loading: false
                });
              } catch (err) {
                actions.setSubmitting(false);
                toggleShowError(true);
              }
            }}
          >
            {({ handleSubmit, isSubmitting, errors, setFieldValue, touched, values }) => (
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid item xs={12}>
                  <Box marginBottom="1rem">
                    <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
                      Update Worker Details
                    </Typography>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <label htmlFor="image" style={{ marginBottom: '0.5rem' }}>
                          <Field name="image">
                            {({ form }) => (
                              <Avatar
                                src={
                                  form.values.image
                                    ? typeof form.values.image === 'string'
                                      ? `${IMAGE_URL}${form.values.image}`
                                      : URL.createObjectURL(form.values.image)
                                    : ''
                                }
                                alt="Uploaded Image"
                                size={120}
                                style={{ border: '4px solid #fff', boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)' }}
                              />
                            )}
                          </Field>
                        </label>
                        <label htmlFor="image">
                          <Input
                            id="image"
                            name="image"
                            type="file"
                            accept="image/jpeg, image/png"
                            style={{ display: 'none' }}
                            onChange={(event) => {
                              const newImage = event.currentTarget.files[0];
                              if (newImage) {
                                setFieldValue('image', newImage);
                              }
                            }}
                          />
                          <IconButton component="span" color="primary" aria-label="upload">
                            <CloudUploadIcon />
                          </IconButton>
                        </label>
                        <ErrorMessage name="image" component="div" style={{ color: 'red' }} />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput
                        name="firstName"
                        label="First Name"
                        placeholder="Enter First Name"
                        readOnly={false}
                        withAsterisk={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput name="lastName" label="Last Name" placeholder="Enter Last Name" readOnly={false} withAsterisk={true} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomMultiSelect
                        name="role"
                        label="Role"
                        placeholder="Select Role"
                        options={[
                          { name: 'Hair Stylist', id: 'Hair Stylist' },
                          { name: 'Hair Barber', id: 'Hair Barber' },
                          { name: 'Esthetician', id: 'Esthetician' },
                          { name: 'Nail Technician', id: 'Nail Technician' },
                          { name: 'Makeup Artist', id: 'Makeup Artist' },
                          { name: 'Salon Manager', id: 'Salon Manager' },
                          { name: 'Receptionist', id: 'Receptionist' },
                          { name: 'Shampoo Technician', id: 'Shampoo Technician' },
                          { name: 'Shampoo Assistant', id: 'Shampoo Assistant' }
                        ]}
                        cleared={false}
                        withAsterisk={true}
                      />
                    </Grid>
                    <Grid item xs={6} m={0} p={0}>
                      <CustomInput
                        name="phone"
                        label="Phone Number"
                        placeholder="Enter Phone Number"
                        readOnly={false}
                        withAsterisk={true}
                      />
                    </Grid>

                    <Grid item xs={6} m={0} p={0}>
                      <CustomNumberInput
                        name="payment"
                        label="Payment Period(in Days)"
                        placeholder="Ex: 1 day , 15 days"
                        hideControls={false}
                        withAsterisk={true}
                        prefix=" Days"
                      />
                    </Grid>

                    <Grid item xs={6} m={0} p={0}>
                      <CustomNumberInput
                        name="salary"
                        label="Salary"
                        placeholder="Enter Salary"
                        hideControls={true}
                        withAsterisk={true}
                        prefix=" Rwf"
                      />
                    </Grid>

                    <Grid item xs={6} mb={0} pb={0}>
                      <CustomInput name="address" label="Home Address" placeholder="Enter Address" readOnly={false} withAsterisk={true} />
                    </Grid>
                  </Grid>
                </Grid>

                {showError && !updateIsLoading && UpdateIsError && !isSubmitting && (
                  <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                    <ErrorMessageResponse>{UpdateError?.data?.message || UpdateError.message || 'An error occurred'}</ErrorMessageResponse>
                  </Grid>
                )}

                <Box mt={2}>
                  <LoadingButton
                    sx={{ backgroundColor: theme.palette.primary.primary, color: 'white' }}
                    loading={isSubmitting}
                    done={false}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save Changes
                  </LoadingButton>
                </Box>

                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Modal
        opened={serviceModal}
        onClose={() => {
          setServiceModal(false);
          setSelectedWorker(null);
          toggleShowError(false);
        }}
        size="xl"
        centered
        closeOnClickOutside={false}
      >
        <Box>
          <Formik
            initialValues={{
              salonWorkerId: selectedWorker?.targetId,
              // services: selectedWorker?.services
              services:
                selectedWorker?.services.map((service) => ({
                  salonServiceId: service?.id,
                  serviceName: service?.serviceName,
                  workerPrice: service?.workerPrice,
                  servicePrice: service?.servicePrice
                })) || []
            }}
            validationSchema={Yup.object({
              services: Yup.array(
                Yup.object({
                  salonServiceId: Yup.string().required('ID is Required'),
                  serviceName: Yup.string().required('Service Name is required.'),
                  servicePrice: Yup.string().required('Service ID is required.'),
                  workerPrice: Yup.number().required('Worker Price is required.').min(1, 'Worker Price can not be zero ')
                })
              ).min(1, 'You need to provide at least 1 service!')
            })}
            onSubmit={async (values, actions) => {
              try {
                const result = await AddServiceToEmployee(values).unwrap();
                actions.resetForm();
                actions.setSubmitting(false);
                setServiceModal(false);
                notifications.show({
                  id: 'salon-service-add',
                  withCloseButton: true,
                  autoClose: 3000,
                  title: 'Service Added',
                  message: `Services Added Successfully!`,
                  icon: <IconX />,
                  loading: false
                });
              } catch (err) {
                actions.setSubmitting(false);
                toggleShowError(true);
              }
            }}
          >
            {({ handleSubmit, isSubmitting, errors, setFieldValue, touched, values }) => (
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <div className={classes.customCard}>
                        <FieldArray name="services">
                          {({ push, remove }) => (
                            <Box marginBottom="1rem">
                              <Typography variant="h5" gutterBottom marginBottom={3}>
                                Services List
                              </Typography>
                              <Grid container spacing={3} style={{ marginBottom: '2rem' }}>
                                <Grid item xs={12}>
                                  <SelectServices
                                    name="services"
                                    label="Add Services"
                                    placeholder="Select services"
                                    options={serviceList}
                                    cleared={true}
                                    readOnly={true}
                                    withAsterisk={true}
                                  />
                                </Grid>
                              </Grid>

                              <Box marginBottom="1rem">
                                <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
                                  Added Services
                                </Typography>
                              </Box>
                              {values.services.map((_, index) => (
                                <Grid container item className={classes.noWrap} key={index} alignItems="center">
                                  <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                      <CustomInput
                                        name={`services.${index}.serviceName`}
                                        label="Services Name"
                                        placeholder="Enter First Name"
                                        readOnly={true}
                                        withAsterisk={true}
                                      />
                                    </Grid>

                                    <Grid item xs={4}>
                                      <Field name={`services.${index}.servicePrice`}>
                                        {({ field, form }) => {
                                          return (
                                            <NumberInput
                                              label="Service Price"
                                              placeholder="Enter Service Price"
                                              withAsterisk
                                              clampBehavior="strict"
                                              min={1}
                                              allowNegative={false}
                                              hideControls={true}
                                              thousandSeparator=","
                                              suffix=" Rwf"
                                              {...field}
                                              readOnly={true}
                                            />
                                          );
                                        }}
                                      </Field>
                                    </Grid>

                                    <Grid item xs={4}>
                                      <Field name={`services.${index}.workerPrice`}>
                                        {({ field, form }) => {
                                          return (
                                            <NumberInput
                                              label="Worker Price"
                                              placeholder="Enter Worker Price"
                                              withAsterisk
                                              clampBehavior="strict"
                                              min={1}
                                              allowNegative={false}
                                              hideControls={true}
                                              thousandSeparator=","
                                              suffix=" Rwf"
                                              {...field}
                                              error={
                                                form.touched.services &&
                                                form.touched.services[index] &&
                                                form.errors.services &&
                                                form.errors.services[index]?.workerPrice
                                              }
                                              onChange={(e) => {
                                                const newValue = e;
                                                form.setFieldValue(`services.${index}.workerPrice`, newValue);
                                              }}
                                            />
                                          );
                                        }}
                                      </Field>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm="auto" ml={3} mt="auto">
                                    <ActionIcon
                                      size={35}
                                      variant="filled"
                                      color="red"
                                      disabled={isSubmitting}
                                      onClick={() => remove(index)}
                                    >
                                      <DeleteIcon />
                                    </ActionIcon>
                                  </Grid>
                                </Grid>
                              ))}
                            </Box>
                          )}
                        </FieldArray>
                        {values.services.length === 0 && <ErrorMessage name="services" component="div" className={classes.errorColor} />}{' '}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {!AddServiceToEmployeeIsLaoding && AddServiceToEmployeeIsError && !isSubmitting && (
                  <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                    <ErrorMessageResponse>
                      {AddServiceToEmployeeError?.data?.message || AddServiceToEmployeeError.message || 'An error occurred'}
                    </ErrorMessageResponse>
                  </Grid>
                )}

                <Box mt={2}>
                  <LoadingButton
                    sx={{ backgroundColor: theme.palette.primary.primary, color: 'white' }}
                    loading={isSubmitting}
                    done={false}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save Changes
                  </LoadingButton>
                </Box>

                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Modal
        opened={viewModal}
        onClose={() => {
          setViewModal(false);
          setViewDetails(null);
          toggleShowError(false);
        }}
        size="xl"
        centered
        closeOnClickOutside={false}
      >
        <ModalContent>
          <ImageContainer>
            <AvatarContainer>
              <label htmlFor="image" style={{ marginBottom: '0.5rem' }}>
                <img
                  src={
                    viewDetails?.image
                      ? typeof viewDetails?.image === 'string'
                        ? `${IMAGE_URL}${viewDetails?.image}`
                        : URL.createObjectURL(viewDetails?.image)
                      : ''
                  }
                  alt="Uploaded"
                  style={{ width: '60%', height: '60%', border: '4px solid #fff', boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)' }}
                />
              </label>
            </AvatarContainer>
          </ImageContainer>
          <UserDetailsContainer>
            <Typography variant="h5" color={theme.palette.primary.primary} gutterBottom>
              Worker Details
            </Typography>
            <UserDetails>
              <DetailField label="First Name" value={viewDetails?.firstName} />
              <DetailField label="Last Name" value={viewDetails?.lastName} />
              <DetailField label="Role" value={viewDetails?.role} />
              <DetailField label="Phone" value={viewDetails?.phone} />
              <DetailField label="Payment Period" value={`${viewDetails?.paymentPeriod} Days`} />
              <DetailField label="Salary" value={`${formatNumberWithCommas(viewDetails?.salary || 0)} Rwf`} />
              <DetailField label="Address" value={viewDetails?.homeAddress} />
            </UserDetails>
          </UserDetailsContainer>
        </ModalContent>
      </Modal>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">Workers</Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: theme.palette.primary.primary, color: 'white' }}
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() => open()}
        >
          New Worker
        </Button>
      </Stack>

      {workerLoading ? (
        RenderSkeletons()
      ) : (
        <Card>
          <UserTableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          {companyUserList && companyUserList?.length > 0 ? (
            <>
              <Scrollbar>
                <TableContainer sx={{ overflow: 'unset' }}>
                  <Table sx={{ minWidth: 800 }}>
                    <UserTableHead
                      order={order}
                      orderBy={orderBy}
                      rowCount={companyUserList.length}
                      numSelected={selected.length}
                      onRequestSort={handleSort}
                      onSelectAllClick={handleSelectAllClick}
                      headLabel={[
                        { id: '' },
                        { id: 'name', label: 'Name' },
                        { id: 'role', label: 'Role', align: 'center' },
                        { id: 'company', label: 'Phone' },
                        { id: 'services', label: 'Services', align: 'center' },
                        { id: 'salary', label: 'Salary' },
                        { id: 'status', label: 'Status' }
                      ]}
                    />
                    <TableBody>
                      {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <UserTableRow
                          key={row.id}
                          targetId={row.id}
                          name={row.fullName}
                          role={row.role}
                          status={row.status}
                          company={row.phone}
                          salary={row.salary}
                          avatarUrl={row.image}
                          services={row.services}
                          all={row}
                          selected={selected.indexOf(row.name) !== -1}
                          handleClick={(event) => handleClick(event, row.name)}
                          handleModalService={handleModalService}
                          handleUpdateWorker={handleUpdateWorker}
                          handleViewWorker={handleViewWorker}
                        />
                      ))}

                      <TableEmptyRows height={77} emptyRows={emptyRows(page, rowsPerPage, companyUserList.length)} />

                      {notFound && <TableNoData query={filterName} />}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                page={page}
                component="div"
                count={companyUserList.length}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <NoDataFound message="No Data Found!" />
          )}
        </Card>
      )}
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0'
  },
  card: {
    backgroundColor: '#2196F3',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '2rem',
    color: '#fff',
    textAlign: 'center'
  },
  customCard: {
    backgroundColor: '#f5f5f5', // Light Gray
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '20px',
    margin: '1rem 0',
    transition: 'box-shadow 0.3s ease-in-out',

    '&:hover': {
      boxShadow: '0 8px 12px rgba(0, 0, 0, 0.2)'
    }
  },

  errorColor: {
    color: theme.palette.error.main
  },
  noWrap: {
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap'
    }
  }
}));

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  flex: 1;
`;
const UserDetailsContainer = styled.div`
  flex: 1;
  padding: 20px;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserDetailsField = styled.div`
  margin-bottom: 10px;
`;

const AvatarContainer = styled.div`
  margin-bottom: 10px;
  text-align: center;
`;

const DetailField = ({ label, value }) => (
  <UserDetailsField>
    <Typography variant="body1">
      <strong>{label}:</strong> {value}
    </Typography>
  </UserDetailsField>
);
