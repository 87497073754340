// assets
import NavigationOutlinedIcon from '@mui/icons-material/NavigationOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleIcon from '@mui/icons-material/People';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { ReceiptLong } from '@mui/icons-material';
const icons = {
  NavigationOutlinedIcon: NavigationOutlinedIcon,
  HomeOutlinedIcon: HomeOutlinedIcon,
  ChromeReaderModeOutlinedIcon: ChromeReaderModeOutlinedIcon,
  HelpOutlineOutlinedIcon: HelpOutlineOutlinedIcon,
  SecurityOutlinedIcon: SecurityOutlinedIcon,
  AccountTreeOutlinedIcon: AccountTreeOutlinedIcon,
  BlockOutlinedIcon: BlockOutlinedIcon,
  AppsOutlinedIcon: AppsOutlinedIcon,
  ContactSupportOutlinedIcon: ContactSupportOutlinedIcon,
  DashboardCustomizeIcon: DashboardCustomizeIcon,
  HeatPumpIcon: HeatPumpIcon,
  ShoppingCartIcon: ShoppingCartIcon,
  LoyaltyIcon: LoyaltyIcon,
  WarehouseIcon: WarehouseIcon,
  DynamicFeedIcon: DynamicFeedIcon,
  AdminPanelSettingsIcon: AdminPanelSettingsIcon,
  PeopleIcon: PeopleIcon,
  WorkHistoryIcon: WorkHistoryIcon,
  AddBusinessIcon: AddBusinessIcon,
  ReceiptLong: ReceiptLong
};

export default {
  items: [
    {
      id: 'navigation',
      type: 'group',
      icon: icons['NavigationOutlinedIcon'],
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: icons['DashboardCustomizeIcon'],
          url: '/dashboard',
          allowedRoles: ['1', '2'],
          allowedTypes: ['shop', 'salon', 'bar']
        },
        {
          id: 'business-list',
          title: 'Business',
          type: 'item',
          icon: icons['AdminPanelSettingsIcon'],
          url: '/business',
          allowedRoles: ['0'],
          allowedTypes: ['vendor']
        },
        {
          id: 'business-application',
          title: 'Applicants',
          type: 'item',
          icon: icons['AdminPanelSettingsIcon'],
          url: '/business-application',
          allowedRoles: ['0'],
          allowedTypes: ['vendor']
        },
        {
          id: 'business-reg',
          title: 'Add Business',
          type: 'item',
          icon: icons['AddBusinessIcon'],
          url: '/business-registration',
          allowedRoles: ['0'],
          allowedTypes: ['vendor']
        },
        {
          id: 'user-permission',
          title: 'User Permission',
          type: 'item',
          icon: icons['PeopleIcon'],
          url: '/user-permission',
          allowedRoles: ['0'],
          allowedTypes: ['vendor']
        },
        {
          id: 'all-user',
          title: 'All Users',
          type: 'item',
          icon: icons['PeopleIcon'],
          url: '/all-user',
          allowedRoles: ['0'],
          allowedTypes: ['vendor']
        },
        {
          id: 'salonworker',
          title: 'Salon Worker',
          type: 'item',
          icon: icons['WorkHistoryIcon'],
          url: '/salon/worker',
          allowedRoles: ['2'],
          allowedTypes: ['salon']
        },
        {
          id: 'conditions',
          title: 'Conditions',
          type: 'item',
          url: '/conditions',
          icon: icons['HeatPumpIcon'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['shop', 'bar']
        },
        {
          id: 'sales',
          title: 'Sales',
          type: 'item',
          url: '/sales',
          icon: icons['LoyaltyIcon'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['shop', 'salon']
        },
        {
          id: 'proforma',
          title: 'Proforma',
          type: 'item',
          url: '/proforma',
          icon: icons['ReceiptLong'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['shop', 'salon']
        },
        {
          id: 'sales-bar',
          title: 'Bar Sales',
          type: 'item',
          url: '/bar/sales',
          icon: icons['LoyaltyIcon'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['bar']
        },
        {
          id: 'revenue',
          title: 'Service Revenue',
          type: 'item',
          url: '/salon/revenue',
          icon: icons['LoyaltyIcon'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['salon']
        },
        {
          id: 'purchases',
          title: 'Purchases',
          type: 'item',
          url: '/purchases',
          icon: icons['ShoppingCartIcon'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['shop', 'salon', 'bar']
        },
        {
          id: 'stock',
          title: 'Stock',
          type: 'item',
          url: '/stock',
          icon: icons['WarehouseIcon'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['shop', 'salon', 'bar']
        },
        {
          id: 'current-price',
          title: 'Current Price',
          type: 'item',
          url: '/current-price',
          icon: icons['WarehouseIcon'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['shop', 'salon', 'bar']
        },
        {
          id: 'production',
          title: 'Production',
          type: 'item',
          url: '/production',
          icon: icons['WarehouseIcon'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['shop', 'salon', 'bar']
        },
        {
          id: 'user-closing',
          title: 'User Closing',
          type: 'item',
          url: '/user-closing',
          icon: icons['WarehouseIcon'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['shop', 'salon', 'bar']
        },
        {
          id: 'payments',
          title: 'Payments',
          type: 'item',
          url: '/salon/payment',
          icon: icons['WarehouseIcon'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['salon']
        },
        {
          id: 'report',
          title: 'Reports',
          type: 'collapse',
          icon: icons['DynamicFeedIcon'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['shop', 'bar'],
          children: [
            {
              id: 'purchase-report',
              title: 'Purchase Report',
              type: 'item',
              url: '/report/purchase'
            },
            {
              id: 'sales-report',
              title: 'Sales Report',
              type: 'item',
              url: '/report/sales'
            },
            {
              id: 'loan-report',
              title: 'Loan Report',
              type: 'item',
              url: '/report/loan'
            },
            {
              id: 'expense-report',
              title: 'Expenses Report',
              type: 'item',
              url: '/report/expense'
            },
            {
              id: 'closing-report',
              title: 'Closing Report',
              type: 'item',
              url: '/report/income'
            },
            {
              id: 'proforma-report',
              title: 'Proforma Report',
              type: 'item',
              url: '/report/proforma'
            }
          ]
        },
        {
          id: 'salon-report',
          title: 'Reports',
          type: 'collapse',
          icon: icons['DynamicFeedIcon'],
          allowedRoles: ['1'],
          allowedTypes: ['salon'],
          children: [
            {
              id: 'salon-report-2',
              title: 'Services Report',
              type: 'item',
              url: '/salon/report/service-revenue'
            },
            {
              id: 'salon-report-1',
              title: 'Purchase Report',
              type: 'item',
              url: '/report/purchase'
            },

            {
              id: 'salon-report-3',
              title: 'Expenses Report',
              type: 'item',
              url: '/report/expense'
            },
            {
              id: 'salon-report-4',
              title: 'Income Report',
              type: 'item',
              url: '/report/income'
            }
          ]
        },
        {
          id: 'admin',
          title: 'Admin',
          type: 'collapse',
          icon: icons['AdminPanelSettingsIcon'],
          allowedRoles: ['1', '2'],
          allowedTypes: ['shop', 'bar'],
          children: [
            {
              id: 'product-type',
              title: 'Product Type',
              type: 'item',
              url: '/admin/producttype'
            },
            {
              id: 'product-category',
              title: 'Product Category',
              type: 'item',
              url: '/admin/productcategory'
            },
            {
              id: 'product-list',
              title: 'Product List',
              type: 'item',
              url: '/admin/productlist'
            },
            {
              id: 'shop-supplier',
              title: 'Supplier',
              type: 'item',
              url: '/supplier'
            },
            {
              id: 'users',
              title: 'Users',
              type: 'item',
              url: '/admin/users'
            },
            {
              id: 'ebm-settings',
              title: 'EBM Settings',
              type: 'item',
              url: '/admin/ebm-settings'
            }
          ]
        },

        {
          id: 'salon-Admin',
          title: 'Salon Admin',
          type: 'collapse',
          icon: icons['AdminPanelSettingsIcon'],
          allowedRoles: ['1'],
          allowedTypes: ['salon'],
          children: [
            {
              id: 'supplier',
              title: 'Supplier',
              type: 'item',
              url: '/supplier'
            },
            {
              id: 'salon-admin-1',
              title: 'Salon Service',
              type: 'item',
              url: '/admin/services'
            },
            {
              id: 'salon-admin-2',
              title: 'Salon Workers',
              type: 'item',
              url: '/admin/workers'
            },
            {
              id: 'shop-admin-1',
              title: 'Product Type',
              type: 'item',
              url: '/admin/producttype'
            },
            {
              id: 'shop-admin-2',
              title: 'Product Category',
              type: 'item',
              url: '/admin/productcategory'
            },
            {
              id: 'shop-admin-3',
              title: 'Product List',
              type: 'item',
              url: '/admin/productlist'
            },
            {
              id: 'shop-admin-4',
              title: 'Users',
              type: 'item',
              url: '/admin/users'
            }
          ]
        }
      ]
    }
    // {
    //   id: 'pages',
    //   // title: 'Pages',
    //   // caption: 'Prebuild Pages',
    //   type: 'group',
    //   icon: icons['NavigationOutlinedIcon'],
    //   children: [
    //     {
    //       id: 'sample-page',
    //       title: 'Sample Page',
    //       type: 'item',
    //       url: '/sample-page',
    //       icon: icons['ChromeReaderModeOutlinedIcon']
    //     }
    //   ]
    // },
    // {
    //   id: 'utils',
    //   // title: 'Utils',
    //   type: 'group',
    //   icon: icons['AccountTreeOutlinedIcon'],
    //   children: [
    //     {
    //       id: 'util-icons',
    //       title: 'Icons',
    //       type: 'item',
    //       url: 'https://mui.com/material-ui/material-icons/',
    //       icon: icons['AppsOutlinedIcon'],
    //       external: true,
    //       target: true
    //     },
    //     {
    //       id: 'util-typography',
    //       title: 'Typography',
    //       type: 'item',
    //       url: '/utils/util-typography',
    //       icon: icons['FormatColorTextOutlinedIcon']
    //     }
    //   ]
    // },
    // {
    //   id: 'support',
    //   // title: 'Support',
    //   type: 'group',
    //   icon: icons['ContactSupportOutlinedIcon'],
    //   children: [
    //     {
    //       id: 'disabled-menu',
    //       title: 'Disabled Menu',
    //       type: 'item',
    //       url: '#',
    //       icon: icons['BlockOutlinedIcon'],
    //       disabled: true
    //     },
    //     {
    //       id: 'documentation',
    //       title: 'Documentation',
    //       type: 'item',
    //       url: 'https://codedthemes.gitbook.io/materially-react-material-documentation/',
    //       icon: icons['HelpOutlineOutlinedIcon'],
    //       chip: {
    //         label: 'Help?',
    //         color: 'primary'
    //       },
    //       external: true,
    //       target: true
    //     }
    //   ]
    // }
  ]
};
