import { Grid, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  font-weight: ${(props) => (props.header ? 'bold' : 'normal')};
  border-bottom: ${(props) => (props.header ? '2px solid black' : props.hasBorder ? '1px solid #D5D2C8' : 'none')};
  width: 100%;
`;

const DescriptionCell = styled.div`
  flex: 3;
`;

const UnitPriceCell = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const QuantityCell = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const TotalCell = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const TableRow = ({ description, unitPrice, quantity, total, lastRow, header, hasBorder }) => {
  return (
    <StyledRow lastRow={lastRow} header={header} hasBorder={hasBorder}>
      <DescriptionCell>{description}</DescriptionCell>
      <UnitPriceCell>{unitPrice}</UnitPriceCell>
      <QuantityCell>{quantity}</QuantityCell>
      <TotalCell>{total}</TotalCell>
    </StyledRow>
  );
};

function Table({ billInfo }) {
  const tableData = [
    { description: 'Description', unitPrice: 'Unit Price', quantity: 'Quantity', total: 'Total', header: true },
    ...billInfo.products.map((product) => ({
      description: product.productName,
      unitPrice: `Rwf ${Number(product.unitPrice).toFixed(2)}`,
      quantity: Number(product.qty),
      total: `Rwf ${(Number(product.qty) * Number(product.unitPrice)).toFixed(2)}`
    }))
  ];
  return (
    <>
      <StyledTable>
        {tableData.map((rowData, index) => (
          <TableRow
            key={index}
            description={rowData.description}
            unitPrice={rowData.unitPrice}
            quantity={rowData.quantity}
            total={rowData.total}
            header={rowData.header}
            hasBorder={index !== tableData.length - 1}
          />
        ))}
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <SupplierDetails>
            <Typography variant="body1" sx={{ color: '#000000', marginRight: '5px' }}>
              Total :{' '}
            </Typography>
            <Typography variant="body1" sx={{ color: '#000000', fontWeight: 'bold' }}>
              {(billInfo && `Rwf ${billInfo?.salesTotal}`) || ''}
            </Typography>
          </SupplierDetails>
        </Grid>
      </StyledTable>
    </>
  );
}

export default Table;

const SupplierDetails = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '33.33%',
  borderTop: '2px solid black',
  borderBottom: '2px solid black',
  marginTop: '2rem',
  padding: '1rem 0'
});
