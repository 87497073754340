import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import TitleWithAddButton from 'component/Title/Title';
import { CarForm, formatNumberWithCommas } from 'component/Assets/FakeData';
import { useLazyGetAllProformaQuery, useLazyGetSalesTotalsQuery } from 'store/features/slices/salesSlicee';
import MaterialReactTableComponent from 'component/MaterialReactTable';
import { styled } from 'styled-components';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MaterialReactTableFiltered from 'component/MaterialReactTableFiltered';

function ProformaReport() {
  const classes = useStyles();
  const [fetchSales, { data: salesList = [], error: listError, isLoading: salesLoading, isFetching }] = useLazyGetAllProformaQuery();
  const [fetchTotals, { data: Total = 0 }] = useLazyGetSalesTotalsQuery();

  const renderInfo = (data) => {
    return (
      <InfoContainer>
        <Title>Total Sales Amount:</Title>
        <Value>{`${formatNumberWithCommas(data)} Rwf`}</Value>
      </InfoContainer>
    );
  };

  const [filterDates, setFilterDates] = useState({ from: null, to: null, productId: null });

  const handleFilterByDates = () => {
    if (filterDates?.from === null || filterDates?.to === null) {
      setFilterDates((prevFilterDates) => ({
        ...prevFilterDates,
        from: null,
        to: null
      }));
      fetchSales(filterDates);
      fetchTotals(filterDates);
    } else {
      fetchSales(filterDates);
      fetchTotals(filterDates);
    }
  };

  useEffect(() => {
    fetchSales();
    fetchTotals();
  }, []);

  const isResponseInExpectedFormat = useMemo(() => {
    const expectedKeys = [
      'client',
      'companyId',
      'createdAt',
      'id',
      'productName',
      'productShopListId',
      'qty',
      'refCode',
      'revenue',
      'saleDate',
      'saleId',
      'totalPrice',
      'unitPrice',
      'updatedAt'
    ];

    if (salesList && salesList.rows && Array.isArray(salesList.rows) && salesList.count > 0) {
      const firstObjectKeys = Object.keys(salesList.rows[0]);
      return expectedKeys.every((key) => firstObjectKeys.includes(key));
    }
    return false;
  }, [salesList]);

  return (
    <>
      <Grid container spacing={0}>
        <TitleWithAddButton title="Proforma Report" showAddButton={false} />
      </Grid>
      <Grid container spacing={1} marginTop={2}>
        <Grid item lg={12} xs={12}>
          {isResponseInExpectedFormat ? (
            <MaterialReactTableFiltered
              dataList={salesList?.rows || []}
              filterDates={filterDates}
              setFilterDates={setFilterDates}
              handleFilterByDates={handleFilterByDates}
              isFetching={salesLoading}
              title="PROFORMA"
            />
          ) : (
            <MaterialReactTableComponent
              dataList={salesList}
              type="sale"
              filterDates={filterDates}
              setFilterDates={setFilterDates}
              handleFilterByDates={handleFilterByDates}
              isFetching={salesLoading}
              title="PROFORMA"
            />
          )}
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="Center" alignItems="flex-end" mt={3}>
        {salesList.count && salesList.count > 0 && renderInfo(Total)}
      </Grid>
    </>
  );
}

export default ProformaReport;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0'
  },
  card: {
    backgroundColor: '#2196F3',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '2rem',
    color: '#fff',
    textAlign: 'center'
  }
}));

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #333;
  @media (max-width: 1189px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    font-size: 12px; /* Smaller font size on mobile devices */
  }
`;

const Value = styled.span`
  background-color: #5ba65a;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  margin-left: 4px;

  @media (max-width: 768px) {
    font-size: 16px; /* Smaller font size on mobile devices */
  }
`;
