import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';

export const expenseApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createExpense: builder.mutation({
      query: (data) => {
        return {
          url: 'company/expense/create',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      invalidatesTags: ['expense'],
      overrideExisting: true
    }),
    getAllExpense: builder.query({
      query: () => 'company/expense/all?limit=50',
      refetchOnMount: 'always',
      refetchOnReconnect: true,
      refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        response?.data?.rows && response?.data?.rows?.length > 0 ? response?.data?.rows.sort((a, b) => b.id - a.id) : [],
      providesTags: ['expense'],
      overrideExisting: true
    }),
    getLoans: builder.query({
      query: (filterDates) => {
        let apiUrl = 'company/loan/all?limit=100';
        if (filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        return apiUrl;
      },
      refetchOnMount: 'always',
      refetchOnReconnect: true,
      refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) =>
        response?.data?.rows && response?.data?.rows?.length > 0 ? response?.data?.rows.sort((a, b) => b.id - a.id) : [],
      providesTags: ['loan'],
      overrideExisting: true
    })
  })
});

export const { useCreateExpenseMutation, useGetAllExpenseQuery, useLazyGetLoansQuery } = expenseApi;

const expenseSlice = createSlice({
  name: 'expense',
  initialState: {
    expense: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(expenseApi.endpoints.createExpense.matchFulfilled, (state, action) => {
      console.log(action.payload.data);
    });
  }
});

export const expenseReducer = expenseSlice.reducer;

export default expenseSlice;
