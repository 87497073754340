import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const SessionEndedAlert = ({ open, onClose }) => {
  return (
    <Snackbar open={open} onClose={onClose} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <MuiAlert elevation={6} variant="filled" onClose={onClose} severity="error">
        Your session has ended. You have been logged out.
      </MuiAlert>
    </Snackbar>
  );
};

export default SessionEndedAlert;
