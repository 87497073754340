import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./useAuth";
import { useLazyGetUserByIdQuery } from "store/features/slices/userSlice";

const RequireAuth = ({ children }) => {
	const { auth } = useAuth();
	const location = useLocation();

	return auth ? (
		children
	) : (
		<Navigate to="/login" state={{ from: location }} replace />
	);
};

export default RequireAuth;
