import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';
import dayjs from 'dayjs';

export const stockClosingApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createClosing: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/sales/create_closing',
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        };
      },
      invalidatesTags: ['stock-closing'],
      overrideExisting: true
    }),
    createUserClosing: builder.mutation({
      query: (data) => {
        return {
          url: 'shop/sales/create_user_closing',
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        };
      },
      invalidatesTags: ['stock-closing'],
      overrideExisting: true
    }),
    payLoan: builder.mutation({
      query: (data) => {
        return {
          url: 'company/loan/repay',
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        };
      },
      invalidatesTags: ['loan'],
      overrideExisting: true
    }),
    getIncomeReport: builder.query({
      query: ({ from, to, userId, type }) => {
        let apiUrl = 'shop/report/income?limit=100';
        apiUrl += `&from=${from}&to=${to}&userId=${userId}&type=${type}`;
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response?.data?.rows,
      providesTags: ['stock-closing', 'purchase', 'sales'],
      overrideExisting: true
    }),
    getMoneyAllocation: builder.query({
      query: (filterDates) => {
        let apiUrl = 'company/money_allocation?limit=100';
        if (filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      providesTags: ['stock-closing', 'purchase', 'sales'],
      overrideExisting: true
    }),
    getUserMoneyAllocation: builder.query({
      query: (filterDates) => {
        let apiUrl = 'company/money_allocation/user?limit=100';
        if (filterDates?.from && filterDates?.to && filterDates?.from !== null && filterDates?.to !== null) {
          apiUrl += `&from=${filterDates?.from}&to=${filterDates?.to}`;
        }
        if (filterDates?.userId) apiUrl += `&userId=${filterDates?.userId}`;
        return apiUrl;
      },
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      providesTags: ['stock-closing', 'purchase', 'sales'],
      overrideExisting: true
    }),
    getSalesMoney: builder.query({
      query: () => 'shop/totals/salesTotal',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['stock-closing', 'purchase', 'sales'],
      overrideExisting: true
    }),
    getTodayStock: builder.query({
      query: () => 'shop/sales/get_stock_today',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data.rows,

      providesTags: ['stock-closing', 'purchase', 'sales'],
      overrideExisting: true
    }),
    getTodayCurrentStock: builder.query({
      query: () => 'shop/product/get-products/categoryId/0',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      // pollingInterval: 15 * 60 * 1000,
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales', 'purchase'],
      overrideExisting: true
    }),
    getTodaySales: builder.query({
      query: () => 'shop/sales/all?closing=0',
      // refetchOnMount: 'always',
      refetchOnReconnect: true,
      // refetchOnFocus: 'always',
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['sales', 'purchase'],
      overrideExisting: true
    })
  })
});

export const {
  usePayLoanMutation,
  useCreateClosingMutation,
  useCreateUserClosingMutation,
  useGetIncomeReportQuery,
  useGetSalesMoneyQuery,
  useGetTodayCurrentStockQuery,
  useGetTodaySalesQuery,
  useGetTodayStockQuery,
  useLazyGetIncomeReportQuery,
  useLazyGetMoneyAllocationQuery,
  useLazyGetUserMoneyAllocationQuery
} = stockClosingApi;

const stockClosingSlice = createSlice({
  name: 'stockClosing',
  initialState: {
    stockClosing: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(stockClosingApi.endpoints.createClosing.matchFulfilled, (state, action) => {});
  }
});

export const stockClosingReducer = stockClosingSlice.reducer;

export default stockClosingSlice;
