import { BACKEND_URL } from '../../../config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const token = state?.user?.token;

      if (token) headers.set('authorization', `Bearer ${token}`);
      return headers;
    }
  }),
  tagTypes: [
    'purchases-type',
    'companies',
    'company-users',
    'product-category',
    'product-list',
    'purchase',
    'sales',
    'expense',
    'stock-closing',
    'change-product-status',
    'services',
    'worker',
    'company-supplier',
    'user-permission'
  ],
  endpoints: (builder) => ({})
});
