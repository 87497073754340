import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';
import { jwtDecode } from 'jwt-decode';

const saveTokenToLocalStorage = (token) => {
  localStorage.setItem('auth-token', token);
};

const removeTokenFromLocalStorage = () => {
  localStorage.removeItem('auth-token');
};

// Define the user API endpoints
export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (id) => {
        let apiUrl = 'user/all';
        if (id) {
          apiUrl += `/${id}`;
        }
        return apiUrl;
      },
      refetchOnMount: 'always',
      refetchOnReconnect: true,
      refetchOnFocus: 'always',
      // pollingInterval: 15 * 60 * 1000,
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data,
      providesTags: ['users']
    }),
    getUserById: builder.query({
      query: (id) => `user/user/${id}`,
      refetchOnReconnect: true,
      retry: 3,
      retryOnNetworkFailure: true,
      maxRetries: 3,
      transformResponse: (response, meta, arg) => response.data
    }),

    loginUser: builder.mutation({
      query: (credentials) => {
        const formData = new FormData();
        formData.append('email', credentials.username);
        formData.append('password', credentials.password);
        return {
          url: 'auth/signin',
          method: 'POST',
          body: formData
        };
      }
    }),

    createUser: builder.mutation({
      query: (credentials) => {
        return {
          url: 'auth/register',
          method: 'POST',
          body: JSON.stringify(credentials),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    changePassword: builder.mutation({
      query: (credentials) => {
        return {
          url: 'user/change-secret',
          method: 'POST',
          body: JSON.stringify(credentials),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    resetPassword: builder.mutation({
      query: (credentials) => {
        return {
          url: 'user/forgot-secret',
          method: 'POST',
          body: JSON.stringify(credentials),
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    })
  })
});

// Export the individual endpoint hooks
export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useLoginUserMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useCreateUserMutation,
  useLazyGetUserByIdQuery
} = userApi;

// Create the user slice
const userSlice = createSlice({
  name: 'user',
  initialState: {
    info: [],
    token: null
  },
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    clearUserAndToken: (state) => {
      state.info = [];
      state.token = null;
      removeTokenFromLocalStorage();
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getUsers.matchFulfilled, (state, action) => {
      state.info = action.payload;
    });
    builder.addMatcher(userApi.endpoints.getUserById.matchFulfilled, (state, action) => {
      let ebm = localStorage.getItem('ebm') || jwtDecode(state.token)?._ebmMode;
      localStorage.removeItem('ebm');

      const { uuid, email, fullName, companyId, role, permissions } = action.payload;
      state.info = { id: uuid, email, fullName, companyId, role, ebm, permissions };
    });
    builder.addMatcher(userApi.endpoints.loginUser.matchFulfilled, (state, action) => {
      const data = action?.payload?.accessToken;
      const decodedToken = jwtDecode(data);

      if (data !== null && data !== 'null' && data !== undefined && data !== 'undefined') {
        saveTokenToLocalStorage(data || null);
        state.token = data;
        state.info = { ...action?.payload?.user, token: decodedToken };
      }
    });
  }
});

// Export the user reducer
export const userReducer = userSlice.reducer;

export const { setToken, clearUserAndToken } = userSlice.actions;

// Export the user slice
export default userSlice;
