import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { apiSlice } from './features/api/apiSlice';
import userSlice from './features/slices/userSlice';
import logger from 'redux-logger';
import ExistingReducer from './reducer';
import companySlice from './features/slices/companySlice';
import productSlice from './features/slices/productSlice';
import salesSlice from './features/slices/salesSlicee';
import expenseSlice from './features/slices/expenseSlice';
import stockClosingSlice from './features/slices/stockClosingSlice';
import dashboardSlice from './features/slices/dashboardSlice';
import serviceSlice from './features/slices/salon/serviceSlice';
import workerSlice from './features/slices/salon/workerSlice';

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  user: userSlice.reducer,
  existing: ExistingReducer,
  company: companySlice.reducer,
  product: productSlice.reducer,
  sales: salesSlice.reducer,
  expense: expenseSlice.reducer,
  stockClosing: stockClosingSlice.reducer,
  dashboard: dashboardSlice.reducer,

  // SALON SLICE
  service: serviceSlice.reducer,
  worker: workerSlice.reducer
});

const isDevelopment = process.env.NODE_ENV === 'development';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware().concat(apiSlice.middleware);
    if (isDevelopment) {
      // middleware = middleware.concat(logger);
    }

    return middleware;
  },
  devTools: false
});
