import { Button, Container, Modal, TextInput, Grid, FileButton, UnstyledButton, Loader, PasswordInput } from '@mantine/core';
import { Formik, Field } from 'formik';
import styled from 'styled-components';

export const CustomInput = ({ label, name, placeholder, icon, readOnly, withAsterisk, disabled }) => {
  return (
    <Field name={name}>
      {({ field, meta, form }) => (
        <div>
          <TextInput
            {...field}
            placeholder={placeholder}
            label={label}
            disabled={disabled}
            rightSection={icon}
            readOnly={readOnly}
            withAsterisk={withAsterisk}
            error={form.touched[name] && form.errors[name] ? form.errors[name] : false}
          />
        </div>
      )}
    </Field>
  );
};

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffe8e8;
  border-width: 1px;
  border-color: #d8000c;
  border-radius: 4px;
  padding: 10px;
`;

const ErrorText = styled.div`
  color: #d8000c;
  font-size: 14px;
  font-weight: bold;
`;
