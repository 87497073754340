import React from 'react';
import { Field } from 'formik';
import { Select } from '@mantine/core';
import styled from 'styled-components';

const CustomSelect = ({ label, name, placeholder, options, cleared, withAsterisk, disabled, nameBased = false, useAttribute }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        // const selectedOption = options.find((option) => {
        //   return option.id === field.value;
        // });

        const selectedOption = options.find((option) => {
          if (nameBased) {
            return option.label || option.name === field.value;
          } else {
            return option.id === field.value;
          }
        });

        const handleChange = (option) => {
          const selectedOption = options.find((opt) => opt.label || opt.name === option);
          form.setFieldValue(name, selectedOption ? (nameBased ? selectedOption.name || selectedOption.label : selectedOption.id) : '');
          nameBased && useAttribute && form.setFieldValue(useAttribute, selectedOption.id);
        };

        return (
          <div>
            <Select
              label={label}
              placeholder={placeholder}
              data={Array.from(new Set(options.map((option) => option.label || option.name)))}
              value={selectedOption ? selectedOption.label || selectedOption.name : ''}
              onChange={handleChange}
              searchable
              disabled={disabled}
              clearable={cleared}
              withAsterisk={withAsterisk}
              error={form.touched[name] && form.errors[name] ? form.errors[name] : false}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default CustomSelect;
