import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import TitleWithAddButton from 'component/Title/Title';
import DataGroupedTable from 'component/DataGroupAdvanced';
import MaterialReactTableComponent from 'component/MaterialReactTable';
import { useLazyGetPurchaseTotalsQuery, useLazyGetAllPurchasesQuery } from 'store/features/slices/purchaseSlice';
import { styled } from 'styled-components';
import { formatNumberWithCommas } from 'component/Assets/FakeData';

function PurchaseReport() {
  const classes = useStyles();
  const [fetchPurchase, { data: purchaseList = [], error: listError, isLoading: purchaseIsLoading, isFetching }] =
    useLazyGetAllPurchasesQuery();

  const [fetchTotals, { data: Total = 0 }] = useLazyGetPurchaseTotalsQuery();

  const [value, setValue] = React.useState('1');
  const [filterDates, setFilterDates] = useState({ from: null, to: null, user: null });

  const handleFilterByDates = () => {
    if (filterDates?.user || (filterDates?.from !== null && filterDates?.to !== null)) {
      fetchPurchase(filterDates);
      fetchTotals(filterDates);
    } else {
      console.log("Please select both 'From' and 'To' dates before searching.");
    }
  };

  useEffect(() => {
    fetchPurchase();
    fetchTotals();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderInfo = (data) => {
    return (
      <>
        <InfoContainer>
          <Title>Total Purchase Amount:</Title>
          <Value>{`${formatNumberWithCommas(data)} Rwf`}</Value>
        </InfoContainer>
      </>
    );
  };

  return (
    <>
      <Grid container spacing={0}>
        <TitleWithAddButton title="Purchase Report" showAddButton={false} />
      </Grid>

      <Grid container spacing={1} marginTop={2}>
        <Grid item lg={12} xs={12}>
          <MaterialReactTableComponent
            dataList={purchaseList}
            type="purchase"
            filterDates={filterDates}
            setFilterDates={setFilterDates}
            handleFilterByDates={handleFilterByDates}
            isFetching={purchaseIsLoading}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="Center" alignItems="flex-end" mt={3}>
        {purchaseList.rows && purchaseList.rows.length > 0 && renderInfo(Total)}
      </Grid>
    </>
  );
}

export default PurchaseReport;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0'
  },
  card: {
    backgroundColor: '#2196F3',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
  },
  title: {
    fontSize: '2rem',
    color: '#fff',
    textAlign: 'center'
  }
}));

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #333;
  @media (max-width: 1189px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    font-size: 12px; /* Smaller font size on mobile devices */
  }
`;

const Value = styled.span`
  background-color: #5ba65a;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
  margin-left: 4px;

  @media (max-width: 768px) {
    font-size: 16px; /* Smaller font size on mobile devices */
  }
`;
