import React from 'react';
import { Field } from 'formik';
import { MultiSelect } from '@mantine/core';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffe8e8;
  border-width: 1px;
  border-color: #d8000c;
  border-radius: 4px;
  padding: 10px;
`;

const ErrorText = styled.div`
  color: #d8000c;
  font-size: 14px;
  font-weight: bold;
`;

const CustomMultiSelect = ({ label, name, placeholder, options, cleared, withAsterisk, disabled }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const selectedOptions = field.value || [];
        const handleChange = (selected) => {
          form.setFieldValue(name, selected);
        };

        return (
          <div>
            <MultiSelect
              label={label}
              placeholder={placeholder}
              data={options.map((option) => option.id)}
              value={selectedOptions}
              onChange={handleChange}
              searchable
              clearable={cleared}
              disabled={disabled}
              withAsterisk={withAsterisk}
              error={form.touched[name] && form.errors[name] ? form.errors[name] : false}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default CustomMultiSelect;
