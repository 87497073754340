import { Button, Container, Modal, TextInput, Grid, FileButton, UnstyledButton, Loader, PasswordInput, Textarea } from '@mantine/core';
import { Formik, Field } from 'formik';
import styled from 'styled-components';
import { DatePickerInput } from '@mantine/dates';

export const CustomDatePicker = ({ label, name, placeholder, icon, readOnly, withAsterisk, disabled }) => {
  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(new Date().getHours()).padStart(2, '0');
    const minutes = String(new Date().getMinutes()).padStart(2, '0');
    const seconds = String(new Date().getSeconds()).padStart(2, '0');

    // const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  // Define a function to parse and format the input date string
  function parseAndFormatDate(inputDateString) {
    // Parse the input date string into a JavaScript Date object
    const parsedDate = new Date(inputDateString);

    // Check if the parsed date is valid
    if (isNaN(parsedDate.getTime())) {
      return null; // Return null for invalid dates
    }

    // Format the parsed date into "YYYY-MM-DD" format
    return formatDateToYYYYMMDD(parsedDate);
  }
  return (
    <Field name={name}>
      {({ field, meta, form }) => {
        return (
          <div>
            <DatePickerInput
              {...field}
              placeholder={placeholder}
              valueFormat="DD MMM YYYY"
              label={label}
              value={field?.value ? new Date(field?.value) : null}
              disabled={disabled}
              onChange={(date) => {
                if (date) {
                  const formattedDate = parseAndFormatDate(date);
                  form.setFieldValue(name, formattedDate);
                } else {
                  form.setFieldValue(name, null);
                }
              }}
              withAsterisk={withAsterisk}
              onBlur={() => form.setFieldTouched(name, true)}
              error={form.touched[name] && form.errors[name] ? form.errors[name] : false}
            />
          </div>
        );
      }}
    </Field>
  );
};

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffe8e8;
  border-width: 1px;
  border-color: #d8000c;
  border-radius: 4px;
  padding: 10px;
`;

const ErrorText = styled.div`
  color: #d8000c;
  font-size: 14px;
  font-weight: bold;
`;
