import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import ExploreIcon from '@mui/icons-material/Explore'; // You can replace this with any other MUI icon

const NoDataFound = ({ icon = <BlockIcon />, message = 'No data found', buttonLabel, onButtonClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '40vh',
        p: 2,
        borderRadius: '8px',
        backgroundColor: '#f9f9f9'
      }}
    >
      {icon && React.cloneElement(icon, { sx: { fontSize: '5rem', color: '#888' } })}
      <Typography variant="body2" color="textSecondary" sx={{ mt: 2, textAlign: 'center', maxWidth: '300px' }}>
        {message}
      </Typography>
      {buttonLabel && onButtonClick && (
        <IconButton color="primary" onClick={onButtonClick} sx={{ mt: 2 }}>
          <ExploreIcon />
          {buttonLabel}
        </IconButton>
      )}
    </Box>
  );
};

export default NoDataFound;
